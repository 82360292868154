import { createTheme, alpha } from "@mui/material/styles";

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 400,
      md: 768,
      lg: 990,
      xl: 1200,
    },
  },
  palette: {
    primary: {
      main: "#003660",
    },
    secondary: {
      main: "#D14800",
    },
    error: {
      main: "#DA005E",
    },
    info: {
      main: "#CCD7DF",
    },
    text: {
      primary: "#444547",
    },
    contrastThreshold: 4.5,
  },
});

theme.typography.fontFamily = ["TeeFranklin", "Roboto"].join(",");

theme.typography.h1 = {
  fontFamily: ["TeeFranklin", "Roboto"].join(","),
  fontWeight: 400,
  fontSize: "2.1875rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "2.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.8125rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "3.4375rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "4.0625rem",
    marginBottom: "1.25rem",
  },
};

theme.typography.h2 = {
  fontWeight: 400,
  fontSize: "1.5625rem",
  marginBottom: "1.75rem",
  color: theme.palette.text.primary,
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.875rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.1875rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "2.5rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "2.8125rem",
  },
};

theme.typography.h3 = {
  fontWeight: 400,
  fontSize: "1.25rem",
  marginBottom: "0.25rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.375rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5625rem",
    marginBottom: "0.5rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.875rem",
  },
};

theme.typography.h4 = { fontSize: "1.125rem", marginBottom: "0.5rem", fontWeight: 500 };

theme.typography.subtitle2 = {
  fontSize: "0.9375rem",
  color: theme.palette.text.primary,
  fontWeight: 700,
};

theme.components = {
  MuiTypography: {
    defaultProps: {
      fontFamily: ["TeeFranklin", "Roboto"].join(","),
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        "&:hover": {
          color: theme.palette.primary,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontWeight: 700,
        fontSize: "0.9375rem",
        color: theme.palette.text.primary,
        "&.Mui-focused": {
          color: theme.palette.text.primary,
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      outlined: {
        transform: "translate(14px, 0px) scale(1)",
        "&.MuiInputLabel-shrink": {
          transform: "translate(14px, 0px) scale(1)",
        },
      },
      shrink: {
        transform: "translate(14px, 0px) scale(1)",
      },
      asterisk: {
        color: theme.palette.error.main,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        marginTop: "1.3rem",
        background: "#F2F2EE",
        minHeight: "3.125rem",
        borderRadius: "10px",
        [theme.breakpoints.up("xl")]: {
          minHeight: "3.75rem",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#444547",
          borderWidth: "1px",
        },
        "&.Mui-error": {
          backgroundColor: alpha(theme.palette.error.main, 0.1),
        },
        "& legend": {
          width: 0,
        },
      },
      input: {
        minHeight: "3.125rem",
        boxSizing: "border-box",
        [theme.breakpoints.up("xl")]: {
          minHeight: "3.75rem",
        },
      },
      notchedOutline: {
        borderColor: "#e6e6dd",
        transition: "all 0.3s ease",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        height: "3.125rem",
        padding: "0 16px",
        borderRadius: "10px",
        fontWeight: 700,
        letterSpacing: "1px",
        lineHeight: 1.4,
        [theme.breakpoints.up("xl")]: {
          height: "3.75rem",
        },
      },
      contained: {
        "&.Mui-disabled": {
          backgroundColor: theme.palette.primary.main,
          opacity: 0.4,
          color: "white",
        },
      },
      containedSizeSmall: {
        padding: "0 24px",
        height: "2.5rem",
      },
      outlinedPrimary: {
        backgroundColor: "#CCD7DF",
        color: theme.palette.primary.main,
        border: "none",
        "&:hover": {
          backgroundColor: "#E4EBF0",
          border: "none",
        },
        "&.Mui-disabled": {
          backgroundColor: "#CCD7DF",
          color: theme.palette.primary.main,
          opacity: 0.4,
          border: "none",
        },
      },
      outlinedSizeSmall: {
        padding: "0 24px",
        height: "2.5rem",
      },
      textPrimary: {
        height: "auto",
        textDecoration: "underline",
        "&:hover": {
          backgroundColor: "transparent",
          color: theme.palette.primary,
          textDecoration: "underline",
        },
      },
      containedPrimary: {
        "&:hover": {
          backgroundColor: theme.palette.primary,
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        "&:hover": {
          backgroundColor: "transparent",
          color: theme.palette.primary.main,
        },
      },
      colorPrimary: {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      colorPrimary: {
        "&.Mui-checked": {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      gutters: {
        paddingLeft: 0,
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: "1rem",
        marginRight: ".75rem",
      },
    },
  },
  MuiStepper: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiStepIcon: {
    styleOverrides: {
      root: {
        width: "3rem",
        height: "3rem",
        color: theme.palette.info.main,
        zIndex: 1,
        [theme.breakpoints.down("xs")]: {
          width: "2rem",
          height: "2rem",
        },
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      alternativeLabel: {
        top: "1.5rem",
        [theme.breakpoints.down("xs")]: {
          top: "1rem",
          left: "calc(-50% + 15px)",
          right: "calc(-50% + 15px)",
          width: "3rem",
        },
      },
      line: {
        borderColor: theme.palette.info.main,
        borderTopWidth: 4,
      },
    },
  },
  MuiStep: {
    styleOverrides: {
      root: {
        borderColor: "red",
      },
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      labelContainer: {
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        top: 0,
        height: "4px",
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontSize: "1rem",
        fontWeight: "bold",
        background: "#e6e6dd",
        width: "50%",
        maxWidth: "100%",
        "&.Mui-selected": {
          backgroundColor: "#ffffff",
          color: theme.palette.primary.main,
        },
        [theme.breakpoints.up("xl")]: {
          maxWidth: 264,
        },
      },
      labelIcon: {
        minHeight: "auto",
        height: "3.75rem",
      },
    },
  },
};

// props: {
//   MuiTextField: {
//     variant: "outlined",
//   },
//   MuiInputLabel: {
//     shrink: true,
//     disableAnimation: true,
//   },
// },

export default theme;
