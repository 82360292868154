import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useSetRecoilState } from "recoil";
import { hardReset } from "../../store/hard_reset";
import { modalsState } from "../../store/modals_state";
import { deleteAccommodation } from "../../utils/database";

const DeleteModal = () => {
  const [modals, setModals] = useRecoilState(modalsState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const setHardReset = useSetRecoilState(hardReset);
  const [t] = useTranslation("common");

  const handleClose = () => {
    setModals({
      ...modals,
      deleteModal: "",
    });
  };

  const onDelete = () => {
    setLoading(true);
    deleteAccommodation(modals.deleteModal).then((res) => {
      setLoading(false);
      if (res) {
        setHardReset(true);
        handleClose();
      } else {
        setError(true);
      }
    });
  };

  return (
    <Dialog
      open={modals.deleteModal !== ""}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("delete_listing")}</DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid container alignItems="center" justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <DialogContentText id="alert-dialog-description">{error ? t("basic_error") : t("delete_listing_message")}</DialogContentText>
        )}
      </DialogContent>
      {!loading && (
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("back")}
          </Button>
          <Button onClick={error ? handleClose : onDelete} color="primary">
            OK
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DeleteModal;
