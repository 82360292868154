export default class User {
  id: string;
  username: string;
  isAdmin: boolean;
  listings: Array<string>;

  constructor(user: any) {
    this.id = user.id;
    this.username = user.username;
    this.isAdmin = user.isAdmin;
    this.listings = [...user.listings];
  }
}
