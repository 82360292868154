import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { Grid, Button, Typography, CircularProgress, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Components
import Header from "../components/Header";
import NoUserFallback from "../components/NoUserFallback";
import AdminSection from "../components/AdminSection";
import AccommodationList from "../components/AccommodationList";

// Store
import { authUser } from "../store/auth_user";
import { mapData } from "../store/map_data";
import { loadedAccommodations } from "../store/loaded_accommodations";
import { profileState } from "../store/profile_state";

// Utils
import { findAccommodation, createURLFriendlyString } from "../utils/functions";
import { getAccommodation } from "../utils/database";
import Accommodation from "../models/Accommodation";

const useStyles = makeStyles({
  mainGrid: {
    marginTop: 90,
  },
  mainText: {
    textAlign: "center",
    fontSize: 35,
    fontWeight: "bold",
    marginBottom: 40,
  },
  newListingButton: {
    margintTop: 20,
  },
});

const ProfileScreen = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [t] = useTranslation("common");
  const user = useRecoilValue(authUser);
  const mapAccommodations = useRecoilValue(mapData);
  const allAccommodations = useRecoilValue(loadedAccommodations);
  const [userListings, setUserListings] = useRecoilState(profileState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user.id !== "" && userListings.length === 0) {
      setLoading(true);
      fillUserAccommodations().then(() => setLoading(false));
    }

    // eslint-disable-next-line
  }, [user]);

  const fillUserAccommodations = async () => {
    const accs = new Array<Accommodation>();

    for (let i = 0; i < user.listings.length; i++) {
      let tempAcc = findAccommodation(user.listings[i], [...mapAccommodations.readyAccommodations, ...allAccommodations.accommodations]);

      if (!tempAcc) {
        tempAcc = await getAccommodation(user.listings[i]);
        if (tempAcc) {
          accs.push(Accommodation.fromDbObject(tempAcc));
        }
      } else {
        accs.push(tempAcc);
      }
    }

    setUserListings(accs);
  };

  const onNewAccommodationClick = () => {
    navigate({
      pathname: `/accommodation/new`,
    });
  };

  const onAccommodationClick = (id: string, name: string) => {
    const url = createURLFriendlyString(name);
    navigate({
      pathname: "/accommodation/" + id + "/" + url,
    });
  };

  const profile = () => {
    if (loading) {
      return (
        <Grid container alignItems="center" justifyContent="center" style={{ marginTop: 30 }}>
          <CircularProgress />
        </Grid>
      );
    }

    return (
      <Grid container direction="column" className={classes.mainGrid}>
        <Typography variant="h2" style={{ alignSelf: "center" }}>
          {t("my_listings")}
        </Typography>
        <AccommodationList accommodations={userListings} />
        <Button onClick={onNewAccommodationClick} variant="outlined" color="primary">
          {t("create_new_listing")}
        </Button>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Header onNewAccommodationClick={onNewAccommodationClick} onProfileClick={() => {}} />
      <Container maxWidth="xl" style={{ paddingBottom: "2rem" }}>
        {user.id.length === 0 ? <NoUserFallback /> : profile()}
        {user.isAdmin && <AdminSection onClickListing={onAccommodationClick} />}
      </Container>
    </React.Fragment>
  );
};

export default ProfileScreen;
