import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useSetRecoilState } from "recoil";
import Accommodation from "../../models/Accommodation";
import { hardReset } from "../../store/hard_reset";
import { modalsState } from "../../store/modals_state";
import { editAccommodation } from "../../utils/database";

const ToggleActivationModal = () => {
  const [modals, setModals] = useRecoilState(modalsState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [t] = useTranslation("common");
  const setHardReset = useSetRecoilState(hardReset);

  const handleClose = () => {
    setModals({
      ...modals,
      toggleActivationModal: null,
    });
  };

  const onToggleActivation = () => {
    if (modals.toggleActivationModal) {
      setLoading(true);
      let newAcc = Accommodation.clone(modals.toggleActivationModal);
      newAcc.deactivated = !newAcc.deactivated;
      editAccommodation(newAcc).then((res) => {
        setLoading(false);
        if (res) {
          setHardReset(true);
          handleClose();
        } else {
          setError(true);
        }
      });
    }
  };

  return (
    <Dialog
      open={modals.toggleActivationModal !== null}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {modals.toggleActivationModal?.deactivated ? t("activate_listing") : t("deactivate_listing")}
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid container alignItems="center" justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <DialogContentText id="alert-dialog-description">
            {error
              ? t("basic_error")
              : modals.toggleActivationModal?.deactivated
              ? t("activate_listing_message")
              : t("deactivate_listing_message")}
          </DialogContentText>
        )}
      </DialogContent>
      {!loading && (
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("back")}
          </Button>
          <Button onClick={error ? handleClose : onToggleActivation} color="primary">
            OK
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ToggleActivationModal;
