import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Chip, Button, CircularProgress } from "@mui/material";
import { GoogleMap, useLoadScript, Marker, MarkerClusterer, InfoWindow } from "@react-google-maps/api";
import { getAccommodation, getAllLocationsQuery } from "../utils/database";

// Icons
import markerPin from "../assets/MarkerPin.png";

// Models
import Location from "../models/Location";
import Accommodation from "../models/Accommodation";
import { accommodationTypeToString } from "../models/Types";

// Store
import { mapData } from "../store/map_data";
import { loadedAccommodations } from "../store/loaded_accommodations";

interface Props {
  accommodations?: Array<Accommodation>;
  onClick?: (event: any) => void;
  markedLocation?: {
    lat: number;
    lng: number;
  };
  onLabelClick?: (id: string, name: string) => void;
  forceLoading?: boolean;
}

const MapComponent = (props: Props) => {
  const { isLoaded } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY ?? "" });
  const [center, setCenter] = useState<{ lat: number; lng: number } | undefined>();
  const [markerLabel, setMarkerLabel] = useState<JSX.Element | null>(null);
  const [clickedMarker, setClickedMarker] = useState({
    id: "",
    location: {
      lat: 0,
      lng: 0,
    },
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useRecoilState(mapData);
  const homeAccommodations = useRecoilValue(loadedAccommodations);
  const [t] = useTranslation("common");

  const clusterMarkerStyles = [
    {
      url: "images/m1.png",
      height: 40,
      width: 40,
      textColor: "#ffffff",
    },
    {
      url: "images/m2.png",
      height: 40,
      width: 40,
      textColor: "#ffffff",
    },
    {
      url: "images/m3.png",
      height: 40,
      width: 40,
      textColor: "#ffffff",
    },
    {
      url: "images/m4.png",
      height: 40,
      width: 40,
      textColor: "#ffffff",
    },
    {
      url: "images/m5.png",
      height: 40,
      width: 40,
      textColor: "#ffffff",
    },
  ];

  useEffect(() => {
    setMarkerLabel(null);
    setClickedMarker({ id: "", location: { lat: 0, lng: 0 } });

    if (props.forceLoading) {
      //Stockholm as the starting center
      setCenter({ lat: 59.3293, lng: 18.0686 });
      setLoading(true);
      return;
    } else {
      setLoading(false);
    }

    if (!props.accommodations && data.locations.length === 0) {
      setLoading(true);
      getAllLocationsQuery().then(async (result) => {
        const all = await result.fetchAll();
        const locations = all.resources.map((res) => {
          let loc = Location.fromDbObject(res);
          return loc;
        });
        setLoading(false);
        setData({
          ...data,
          locations: locations,
        });
      });
    }

    if (!center || (center.lat === 59.3293 && center.lng === 18.0686)) {
      const onlyOne = props.accommodations && props.accommodations.length === 1;
      let newCenter;
      if (props.markedLocation) {
        newCenter = props.markedLocation;
      } else if (clickedMarker.id !== "") {
        newCenter = clickedMarker.location;
      } else if (onlyOne && props.accommodations) {
        newCenter = props.accommodations[0].location;
      } else if (!center) {
        //Stockholm as the starting center
        newCenter = {
          lat: 59.3293,
          lng: 18.0686,
        };
      } else {
        newCenter = center;
      }
      setCenter(newCenter);
    }
    // eslint-disable-next-line
  }, [props.accommodations, props.markedLocation, props.forceLoading]);

  useEffect(() => {
    if (clickedMarker.id === "") {
      setMarkerLabel(null);
    } else if (props.accommodations && clickedMarker.id !== "") {
      const selected = props.accommodations.filter((acc) => acc.id === clickedMarker.id)[0];
      setMarkerLabel(labelOnMarker(selected));
    } else if (!props.accommodations && clickedMarker.id !== "") {
      let exists = data.readyAccommodations.filter((acc) => acc.id === clickedMarker.id);
      if (exists.length > 0) {
        setMarkerLabel(labelOnMarker(exists[0]));
      } else {
        exists = homeAccommodations.accommodations.filter((acc) => acc.id === clickedMarker.id);
        if (exists.length > 0) {
          setMarkerLabel(labelOnMarker(exists[0]));
        } else {
          setMarkerLabel(labelOnMarker(data.locations.filter((loc) => loc.id === clickedMarker.id)[0]));

          getAccommodation(clickedMarker.id).then((result) => {
            const accommodations = [...data.readyAccommodations];
            accommodations.push(Accommodation.fromDbObject(result));

            setMarkerLabel(labelOnMarker(accommodations[accommodations.length - 1]));
            setData({
              ...data,
              readyAccommodations: [...accommodations],
            });
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [clickedMarker]);

  const labelOnMarker = (obj: any) => {
    return (
      <InfoWindow key={obj.id} position={obj.location ? obj.location : { lat: obj.lat, lng: obj.lng }}>
        <Grid container style={{ width: 350, backgroundColor: "white" }}>
          {!obj.location ? (
            <CircularProgress style={{ margin: "auto" }} />
          ) : (
            <Grid item>
              <div style={{ width: "100%", position: "relative", marginBottom: 10 }}>
                <img src={obj.mainPhoto} style={{ width: "100%" }} alt={obj.name} />
                <Chip
                  label={t(accommodationTypeToString(obj.type))}
                  color="primary"
                  style={{
                    position: "absolute",
                    bottom: "-10px",
                    right: "10px",
                  }}
                />
              </div>
              <Typography variant="h3">{obj.name}</Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  if (props.onLabelClick !== undefined) {
                    props.onLabelClick(obj.id, obj.name);
                  }
                }}
                size="small"
              >
                Läs mer
              </Button>
            </Grid>
          )}
        </Grid>
      </InfoWindow>
    );
  };

  const onClusteringEnd = (markerClusterer: any) => {
    if (props.accommodations && props.accommodations.length < 2) {
      return;
    }

    let showLabel = false;

    markerClusterer.clusters.forEach((cluster: any) => {
      if (cluster.markers.length < 3) {
        cluster.markers.forEach((marker: any) => {
          let markerAcc: any;
          if (props.accommodations) {
            markerAcc = props.accommodations.filter(
              (acc) => acc.location.lat === marker.position.lat() && acc.location.lng === marker.position.lng()
            )[0];
          } else {
            markerAcc = data.locations.filter((loc) => loc.lat === marker.position.lat() && loc.lng === marker.position.lng())[0];
          }

          if (markerAcc && clickedMarker.id === markerAcc.id) {
            showLabel = true;
          }
        });
      }
    });

    if (!showLabel) {
      setClickedMarker({ id: "", location: { lat: 0, lng: 0 } });
    }
  };

  const onMarkerClick = (obj: any) => {
    if (clickedMarker.id === obj.id) {
      setClickedMarker({ id: "", location: { lat: 0, lng: 0 } });
    } else {
      setClickedMarker({
        id: obj.id,
        location: {
          lat: obj.lat ?? obj.location.lat,
          lng: obj.lng ?? obj.location.lng,
        },
      });
    }
  };

  return isLoaded ? (
    <GoogleMap
      zoom={props.accommodations && props.accommodations.length === 1 ? 8 : props.markedLocation ? 12 : 4}
      center={center}
      mapContainerStyle={{ width: "100%", height: "100%" }}
      onClick={props.onClick}
    >
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" style={{ width: "100%", height: "100%" }}>
          <CircularProgress />
        </Grid>
      ) : (
        <MarkerClusterer averageCenter onClusteringEnd={onClusteringEnd} gridSize={30} styles={clusterMarkerStyles}>
          {(clusterer) => {
            if (props.markedLocation) {
              return <Marker key={-1} position={props.markedLocation} clusterer={clusterer} icon={markerPin} />;
            } else {
              if (props.accommodations) {
                return (
                  <>
                    {props.accommodations.map((accommodation) => (
                      <Marker
                        onClick={() => onMarkerClick(accommodation)}
                        key={accommodation.id}
                        position={accommodation.location}
                        clusterer={clusterer}
                        icon={markerPin}
                      />
                    ))}
                  </>
                );
              } else {
                return (
                  <>
                    {data.locations.map((location) => (
                      <Marker
                        onClick={() => onMarkerClick(location)}
                        key={location.id}
                        position={{ lat: parseFloat(location.lat.toString()), lng: parseFloat(location.lng.toString()) }}
                        clusterer={clusterer}
                        icon={markerPin}
                      />
                    ))}
                  </>
                );
              }
            }
          }}
        </MarkerClusterer>
      )}
      {markerLabel}
    </GoogleMap>
  ) : null;
};

export default MapComponent;
