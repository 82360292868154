import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ImageSelector from "../ImageSelector";
import ImageCard from "../ImageCard";
import { useRecoilState } from "recoil";
import { newListingState } from "../../store/new_listing_state";
import { useTranslation } from "react-i18next";
import { Response } from "../ImageSelector";

const useStyles = makeStyles({
  root: {
    marginBottom: "10px",
  },
  centerContainer: {
    width: "100%",
    height: "100%",
    marginBottom: "20px",
  },
});

interface Props {
  error?: boolean;
  onDeleteImage: (value: string) => void;
}

const StepFour = (props: Props) => {
  const [listingState, setListingState] = useRecoilState(newListingState);
  const classes = useStyles();
  const [t] = useTranslation("common");

  useEffect(() => {
    const isValid = checkValidity();
    setListingState({
      ...listingState,
      activeStepValid: isValid,
    });

    // eslint-disable-next-line
  }, [listingState.otherImages, listingState.mainImage]);

  const handleFileChange = (response: Response, selector: string) => {
    if (selector === "main") {
      setListingState({
        ...listingState,
        location: { ...listingState.location },
        mainImage: response.imageURLs,
        mainImageFile: response.files ?? [],
      });
    } else if (selector === "other") {
      setListingState({
        ...listingState,
        location: { ...listingState.location },
        otherImages: listingState.otherImages.concat(response.imageURLs),
        otherImagesFiles:
          response.files !== null ? listingState.otherImagesFiles.concat(response.files) : [...listingState.otherImagesFiles],
      });
    }
  };

  const handleDelete = (fileURL: string) => {
    const filteredListing: string[] = listingState.otherImages.filter(function (value, index, arr) {
      return value !== fileURL;
    });

    setListingState({
      ...listingState,
      location: { ...listingState.location },
      otherImages: filteredListing,
      otherImagesFiles: [],
    });

    props.onDeleteImage(fileURL);
  };

  const checkValidity = () => {
    if (listingState.mainImage.length === 0) {
      return false;
    }
    if (listingState.otherImages.length === 0) {
      return false;
    }
    return true;
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Typography variant="h6">{t("main_image")}</Typography>
      <Grid container justifyContent="center" alignItems="center" className={classes.centerContainer} direction="column">
        {listingState.mainImage.length > 0 ? (
          <ImageSelector error={props.error} imgURL={listingState.mainImage[0]} onFileChange={(e) => handleFileChange(e, "main")} />
        ) : (
          <ImageSelector error={props.error} onFileChange={(e) => handleFileChange(e, "main")} />
        )}
      </Grid>
      <Typography variant="h6">{t("other_images")}</Typography>
      <Grid container justifyContent="flex-start" alignItems="center" className={classes.centerContainer} spacing={1}>
        <Grid item>
          <ImageSelector
            multiple
            onFileChange={(e) => handleFileChange(e, "other")}
            error={listingState.otherImages.length === 0 && props.error}
          />
        </Grid>
        {listingState.otherImages.length > 0
          ? listingState.otherImages.map((imgURL) => {
              return (
                <Grid item key={imgURL}>
                  <ImageCard imgURL={imgURL} onFileDelete={handleDelete} />
                </Grid>
              );
            })
          : null}
      </Grid>
    </Grid>
  );
};

export default StepFour;
