import Accommodation from "../models/Accommodation";
import Resizer from "react-image-file-resizer";

export function typedKeys<T>(o: T): (keyof T)[] {
  return Object.keys(o as {}) as (keyof T)[];
}

export const filterAccomodations = (filters: any, ts: number): string => {
  let resultQuery = "SELECT * FROM accommodations ";

  if (filters.general.trim().length > 0) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `(LOWER(accommodations.name) LIKE '%${filters.general.trim().toLowerCase()}%' or `;
    resultQuery += `CONTAINS(accommodations.contactInfo.name, '${filters.general.trim()}') or `;
    resultQuery += `CONTAINS(accommodations.contactInfo.phone, '${filters.general.trim()}') or `;
    resultQuery += `CONTAINS(accommodations.contactInfo.email, '${filters.general.trim()}') or `;
    resultQuery += `CONTAINS(accommodations.contactInfo.url, '${filters.general.trim()}') or `;
    resultQuery += `CONTAINS(accommodations.contactInfo.owner, '${filters.general.trim()}')) `;
  }
  if (filters.guests[1] > 0) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations.amenities.guests <= ${filters.guests[1]} and accommodations.amenities.guests >= ${filters.guests[0]} `;
  }
  if (filters.beds[1] > 0) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations.amenities.beds <= ${filters.beds[1]} and accommodations.amenities.beds >= ${filters.beds[0]} `;
  }
  if (filters.floorSpaces[1] > 0) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations.amenities.floorSpaces <= ${filters.floorSpaces[1]} and accommodations.amenities.floorSpaces >= ${filters.floorSpaces[0]} `;
  }
  if (filters.type > -1) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations.type = ${filters.type} `;
  }
  if (filters.regions.includes(true)) {
    let selectedRegions: number[] = [];
    filters.regions.forEach((selected: boolean, index: number) => {
      if (selected) {
        selectedRegions.push(index);
      }
    });

    if (selectedRegions.length > 0) {
      selectedRegions.forEach((region: number, index: number) => {
        if (index === 0) {
          resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
          resultQuery += `accommodations.region = ${region} `;
        } else {
          resultQuery += resultQuery.includes("WHERE") ? "or " : "WHERE ";
          resultQuery += `accommodations.region = ${region} `;
        }
      });
    }
  }
  if (filters.kitchen) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations.amenities.kitchens > 0 `;
  }
  if (filters.bathroom) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `ARRAY_LENGTH(accommodations.amenities.bathrooms) > 0 `;
  }
  if (filters.showers) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations.amenities.showers = true `;
  }
  if (filters.electricity) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations.amenities.electricity = true `;
  }
  if (filters.water) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations.amenities.water != 0 `;
  }
  if (filters.sauna) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations.amenities.sauna = true `;
  }
  if (filters.wifi) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations.amenities.wifi = true `;
  }
  if (filters.pets) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations.amenities.petsAllowed = true `;
  }
  if (filters.party) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations.amenities.partyAllowed = true `;
  }
  if (filters.parking) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations.amenities.parking = true `;
  }
  if (filters.handicapAdapted) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations.amenities.handicapAdapted = true `;
  }
  if (filters.publicBeach) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations.amenities.publicBeach = true `;
  }
  if (filters.campfire) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations.amenities.campfire = true `;
  }
  if (filters.publicTransportDistance > 0) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations.amenities.publicTransportDistance <= ${filters.publicTransportDistance} `;
  }
  if (filters.groceryStoreDistance > 0) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations.amenities.groceryStoreDistance <= ${filters.groceryStoreDistance} `;
  }

  resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
  if (ts > 0) {
    resultQuery += `accommodations.approved = true and accommodations.deactivated = false and accommodations._ts < ${ts} `;
  } else {
    resultQuery += "accommodations.approved = true and accommodations.deactivated = false ";
  }

  switch (filters.sortBy) {
    case "alphabetical_desc":
      resultQuery += "ORDER BY accommodations.name DESC ";
      break;
    case "alphabetical_asc":
      resultQuery += "ORDER BY accommodations.name ASC ";
      break;
    case "date_desc":
      resultQuery += "ORDER BY accommodations._ts DESC ";
      break;
    case "date_asc":
      resultQuery += "ORDER BY accommodations._ts ASC ";
      break;
    default:
      resultQuery += "ORDER BY accommodations.name DESC ";
      break;
  }

  return resultQuery;
};

export const filterAdminAccomodations = (
  filters: {
    unapproved: boolean;
    new: boolean;
    edited: boolean;
    deactivated: boolean;
  },
  ts: number
): string => {
  let resultQuery = "SELECT * FROM accommodations ";

  if (filters.unapproved || filters.new || filters.edited) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations.approved = false `;
  }

  if (filters.new) {
    resultQuery += "and ";
    resultQuery += `accommodations.edited = false `;
  } else if (filters.edited) {
    resultQuery += "and ";
    resultQuery += `accommodations.edited = true `;
  } else if (filters.deactivated) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations.deactivated = true `;
  }

  if (ts > 0) {
    resultQuery += resultQuery.includes("WHERE") ? "and " : "WHERE ";
    resultQuery += `accommodations._ts < ${ts} `;
  }

  resultQuery += "ORDER BY accommodations._ts DESC";
  return resultQuery;
};

export const findAccommodation = (id: string, allLoadedAccommodations: Array<Accommodation>) => {
  let tempAccommodation = allLoadedAccommodations.filter((acc) => acc.id === id);
  if (tempAccommodation.length === 0) {
    return null;
  } else {
    return tempAccommodation[0];
  }
};

export const imageResizer = (file: File): Promise<File> => {
  const quality = file.size < 500000 ? 100 : 75;
  return new Promise<File>((resolve) => {
    Resizer.imageFileResizer(
      file,
      1880,
      1342,
      "JPEG",
      quality,
      0,
      (uri) => {
        if (uri instanceof File) {
          resolve(uri);
        }
      },
      "file"
    );
  });
};

export const createAccommodationId = () => {
  const timestamp = Date.now().toString();
  const randNum = Math.floor(Math.random() * 100);
  const id = timestamp + randNum;

  return id;
};

export const createURLFriendlyString = (value: any) => {
  const decoratedCharsRegEx = /[\u0300-\u036f]/g;
  const specialCharsRegEx = /[^a-zA-Z0-9 ]/g;
  const stringWoDecoratedChars = (value as string).normalize("NFD").replace(decoratedCharsRegEx, "");
  const stringWoSpecialChars = stringWoDecoratedChars.replace(specialCharsRegEx, "").replaceAll(" ", "_");
  return stringWoSpecialChars;
};

export const correctUrl = (url: string) => {
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  } else {
    return "http://" + url;
  }
};

export const prettierUrl = (url: string) => {
  if (url.startsWith("http://")) {
    return url.substring(7);
  } else if (url.startsWith("https://")) {
    return url.substring(8);
  } else {
    return url;
  }
};
