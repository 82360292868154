import React, { useState } from "react";
import { Fab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import useWindowDimensions from "../custom_hooks/useWindowDimensions";
import SpeedDialIcon from "@mui/lab/SpeedDialIcon";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import SyncIcon from "@mui/icons-material/Sync";
import CheckIcon from "@mui/icons-material/Check";
import { useRecoilValue } from "recoil";
import { authUser } from "../store/auth_user";
import Accommodation from "../models/Accommodation";

interface Props {
  onClick: () => void;
  text: string;
  color?: string;
  toggleButton?: boolean;
  onOptionClick?: (option: string) => void;
  isFilters?: boolean;
  accommodation?: Accommodation;
}

interface StyleProps {
  color?: string;
  width: number;
  isFilters?: boolean;
}

const useStyles = makeStyles({
  actionButton: (props: StyleProps) => {
    return {
      position: "fixed",
      bottom: props.width <= 700 && props.isFilters ? 60 : 8,
      right: props.width / 2 - 82,
      width: 150,
      backgroundColor: props.color ?? "",
    };
  },
  toggleButton: {
    position: "fixed",
    bottom: 10,
    right: 10,
  },
});

const FloatingButton = (props: Props) => {
  const { width } = useWindowDimensions();
  const classes = useStyles({ width: width, color: props.color, isFilters: props.isFilters });
  const [toggleOpen, setToggleOpen] = useState(false);
  const user = useRecoilValue(authUser);
  const [t] = useTranslation("common");

  const getIcon = () => {
    if (props.isFilters) {
      return <FindReplaceIcon style={{ marginRight: 8 }} />;
    } else {
      return <PersonIcon style={{ marginRight: 8 }} />;
    }
  };

  const handleOpen = () => {
    setToggleOpen(true);
  };

  const handleClose = (key?: string) => {
    if (key && props.onOptionClick) {
      props.onOptionClick(key);
    }
    setToggleOpen(false);
  };

  const dialOptions = () => {
    let options = [
      <SpeedDialAction key="profile" icon={<PersonIcon />} tooltipTitle={t("my_profile")} onClick={() => handleClose("profile")} />,
      <SpeedDialAction
        key="add"
        icon={<AddIcon color="primary" />}
        tooltipTitle={t("create_new_listing")}
        onClick={() => handleClose("add")}
      />,
    ];

    if (props.accommodation && (user.isAdmin || user.listings.includes(props.accommodation.id))) {
      options = [
        ...options,
        <SpeedDialAction
          key="addOwners"
          icon={<GroupAddIcon />}
          tooltipTitle={t("add_co_owners")}
          onClick={() => handleClose("addOwners")}
        />,
        <SpeedDialAction
          key="delete"
          icon={<DeleteIcon color="error" />}
          tooltipTitle={t("delete_listing")}
          onClick={() => handleClose("delete")}
        />,
        <SpeedDialAction
          key="activate_deactivate"
          icon={props.accommodation.deactivated ? <SyncIcon /> : <SyncDisabledIcon />}
          tooltipTitle={props.accommodation.deactivated ? t("activate_listing") : t("deactivate_listing")}
          onClick={() => handleClose("activate_deactivate")}
        />,
        <SpeedDialAction key="edit" icon={<EditIcon />} tooltipTitle={t("edit_this_listing")} onClick={() => handleClose("edit")} />,
      ];
    }

    if (user.isAdmin && props.accommodation && !props.accommodation.approved) {
      options = [
        ...options,
        <SpeedDialAction
          key="approve"
          icon={<CheckIcon style={{ color: "green" }} />}
          tooltipTitle={t("approve_listing")}
          onClick={() => handleClose("approve")}
        />,
      ];
    }

    return options;
  };

  if (props.toggleButton) {
    return (
      <SpeedDial
        className={classes.toggleButton}
        ariaLabel="SpeedDial"
        direction="up"
        icon={<SpeedDialIcon />}
        onClose={() => handleClose()}
        onOpen={handleOpen}
        open={toggleOpen}
      >
        {dialOptions()}
      </SpeedDial>
    );
  }

  return (
    <Fab color="secondary" variant="extended" className={classes.actionButton} onClick={props.onClick}>
      {getIcon()}
      {props.text}
    </Fab>
  );
};

export default FloatingButton;
