import { atom } from "recoil";
import Accommodation from "../models/Accommodation";
import Location from "../models/Location";

export const mapData = atom<{
  locations: Array<Location>;
  readyAccommodations: Array<Accommodation>;
}>({
  key: "mapData",
  default: {
    locations: [],
    readyAccommodations: [],
  },
});
