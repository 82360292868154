import Amenities from "./Amenities";
import { AccommodationType, Region, ToiletType, WaterType } from "./Types";
import User from "./User";
import { createAccommodationId } from "../utils/functions";

export default class Accommodation {
  id: string;
  name: string;
  description: string;
  approved: boolean;
  deactivated: boolean;
  edited: boolean;
  location: {
    lat: number;
    lng: number;
  };
  region: Region;
  mainPhoto: string;
  photos: Array<string>;
  amenities: Amenities;
  type: AccommodationType;
  contactInfo: {
    name: string;
    phone?: string;
    email?: string;
    url?: string;
    owner?: string;
  };
  surroundings: string;
  route: string;
  price: string;

  constructor(
    id: string,
    name: string,
    description: string,
    approved: boolean,
    deactivated: boolean,
    edited: boolean,
    location: { lat: number; lng: number },
    region: Region,
    mainPhoto: string,
    photos: Array<string>,
    amenities: Amenities,
    type: AccommodationType,
    contactInfo: { name: string; phone?: string; email?: string; url?: string; owner?: string },
    surroundings: string,
    route: string,
    price: string
  ) {
    this.id = id;
    this.description = description;
    this.name = name;
    this.approved = approved;
    this.deactivated = deactivated;
    this.edited = edited;
    this.location = location;
    this.region = region;
    this.mainPhoto = mainPhoto;
    this.photos = photos;
    this.amenities = amenities;
    this.type = type;
    this.contactInfo = contactInfo;
    this.surroundings = surroundings;
    this.route = route;
    this.price = price;
  }

  static fromDbObject(obj: any): Accommodation {
    return new Accommodation(
      obj.id,
      obj.name,
      obj.description,
      obj.approved,
      obj.deactivated,
      obj.edited,
      { lat: obj.location.lat, lng: obj.location.lng },
      obj.region,
      obj.mainPhoto,
      [...obj.photos],
      Amenities.fromDbObject(obj),
      obj.type,
      { ...obj.contactInfo },
      obj.surroundings,
      obj.route,
      obj.price.toString()
    );
  }

  static clone(obj: Accommodation): Accommodation {
    return new Accommodation(
      obj.id,
      obj.name,
      obj.description,
      obj.approved,
      obj.deactivated,
      obj.edited,
      { lat: obj.location.lat, lng: obj.location.lng },
      obj.region,
      obj.mainPhoto,
      [...obj.photos],
      Amenities.fromDbObject(obj),
      obj.type,
      { ...obj.contactInfo },
      obj.surroundings,
      obj.route,
      obj.price
    );
  }

  static fromNewListingObject(obj: any, user?: User, id?: string): Accommodation {
    return new Accommodation(
      user ? (id && id !== "" ? id : createAccommodationId()) : "",
      obj.title,
      obj.description,
      user && user.isAdmin ? true : false,
      false,
      user && !user.isAdmin ? (id ? true : false) : false,
      { ...obj.location },
      obj.region,
      obj.mainImage[0],
      [...obj.otherImages],
      new Amenities(
        +obj.guests,
        +obj.bedrooms,
        +obj.beds,
        +obj.floorSpaces,
        +obj.kitchens,
        [...obj.bathrooms],
        obj.waterType,
        obj.electricity,
        obj.wifi,
        obj.petsAllowed,
        obj.partyAllowed,
        obj.parking,
        obj.handicapAdapted,
        obj.publicBeach,
        obj.campfire,
        obj.showers,
        obj.sauna,
        +obj.publicTransportDistance,
        +obj.groceryStoreDistance,
        obj.kitchenEquipment,
        [...obj.otherAmenities],
        obj.more
      ),
      obj.type,
      {
        name: obj.contact_name,
        phone: obj.contact_phone,
        email: obj.contact_email,
        url: obj.contact_url,
        owner: obj.contact_owner,
      },
      obj.surroundings,
      obj.route,
      obj.price
    );
  }

  static getEmptyAccommodationAsObj() {
    return {
      activeStepValid: false,
      id: "",
      title: "",
      description: "",
      type: AccommodationType.Campsite,
      location: {
        lat: 0,
        lng: 0,
      },
      region: Region.Blekinge,
      guests: "",
      bedrooms: "",
      beds: "",
      floorSpaces: "",
      kitchens: "",
      bathrooms: Array<ToiletType>(),
      kitchenEquipment: "",
      waterType: WaterType.None,
      wifi: false,
      electricity: false,
      petsAllowed: false,
      partyAllowed: false,
      parking: false,
      handicapAdapted: false,
      publicBeach: false,
      campfire: false,
      showers: false,
      sauna: false,
      publicTransportDistance: "",
      groceryStoreDistance: "",
      otherAmenities: Array<string>(),
      more: "",
      mainImage: Array<string>(),
      mainImageFile: Array<File>(),
      otherImages: Array<string>(),
      otherImagesFiles: Array<File>(),
      contact_name: "",
      contact_phone: "",
      contact_email: "",
      contact_url: "",
      contact_owner: "",
      surroundings: "",
      route: "",
      price: "",
    };
  }

  getAccommodationAsObj() {
    return {
      activeStepValid: true,
      id: this.id,
      title: this.name,
      description: this.description,
      type: this.type,
      location: this.location,
      region: this.region,
      guests: this.amenities.guests.toString(),
      bedrooms: this.amenities.bedrooms.toString(),
      beds: this.amenities.beds.toString(),
      floorSpaces: this.amenities.floorSpaces.toString(),
      kitchens: this.amenities.kitchens.toString(),
      bathrooms: this.amenities.bathrooms,
      kitchenEquipment: this.amenities.kitchenEquipment,
      waterType: this.amenities.water,
      wifi: this.amenities.wifi,
      electricity: this.amenities.electricity,
      petsAllowed: this.amenities.petsAllowed,
      partyAllowed: this.amenities.partyAllowed,
      parking: this.amenities.parking,
      handicapAdapted: this.amenities.handicapAdapted,
      publicBeach: this.amenities.publicBeach,
      campfire: this.amenities.campfire,
      showers: this.amenities.showers,
      sauna: this.amenities.sauna,
      publicTransportDistance: this.amenities.publicTransportDistance.toString(),
      groceryStoreDistance: this.amenities.groceryStoreDistance.toString(),
      otherAmenities: this.amenities.otherAmenities ?? [],
      more: this.amenities.more ?? "",
      mainImage: [this.mainPhoto],
      mainImageFile: Array<File>(),
      otherImages: this.photos,
      otherImagesFiles: Array<File>(),
      contact_name: this.contactInfo.name,
      contact_phone: this.contactInfo.phone ?? "",
      contact_email: this.contactInfo.email ?? "",
      contact_url: this.contactInfo.url ?? "",
      contact_owner: this.contactInfo.owner ?? "",
      surroundings: this.surroundings,
      route: this.route,
      price: this.price,
    };
  }
}
