export default class Location {
  id: string;
  lat: number;
  lng: number;

  constructor(id: string, lat: number, lng: number) {
    this.id = id;
    this.lat = lat;
    this.lng = lng;
  }

  static fromDbObject(obj: any): Location {
    return new Location(obj.id, obj.lat, obj.lng);
  }
}
