import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Box, Container, Tabs, Tab, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Accommodation from "../models/Accommodation";

// Components
import Header from "../components/Header";
import Wave from "../components/Wave";
import CustomDrawer from "../components/CustomDrawer";
import MapComponent from "../components/MapComponent";
import TabPanel from "../components/TabPanel";
import SearchSection from "../components/SearchSection";
import AccommodationList from "../components/AccommodationList";

// Icons
import AppsIcon from "@mui/icons-material/Apps";
import MapIcon from "@mui/icons-material/Map";

// Store
import { frontpageState } from "../store/frontpage_state";
import { loadedAccommodations } from "../store/loaded_accommodations";
import { filtersState, filtersCountState } from "../store/filters_state";

// Utils
import { getAllAccommodationsQuery } from "../utils/database";
import { filterAccomodations, createURLFriendlyString } from "../utils/functions";
import { SortBySelector } from "../components/SortBySelector";

const useStyles = makeStyles((theme: Theme) => ({
  hero: {
    paddingTop: "50px",
    paddingBottom: "78px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      "&::after": {
        bottom: "-50px",
        right: "-100px",
      },
    },
  },
  heroText: {
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "50%",
    },
  },
  searchContainer: {
    padding: "3rem 2rem",
    [theme.breakpoints.up("lg")]: {
      padding: "3rem 5rem",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "4rem 2rem",
    },
  },
  map: {
    position: "relative",
  },
  mapContainer: {
    width: "100vw",
    height: "90vh",
  },
  subHeaderText: {
    textAlign: "start",
    fontSize: 25,
    marginBottom: 50,
    marginTop: 20,
  },
  tabPanel: {
    background: "#f2f2ee",
    position: "relative",
  },
  cardsContainer: {
    padding: "2rem 2rem",
    [theme.breakpoints.up("lg")]: {
      padding: "2rem 2rem",
    },
  },
  bottomNavigation: {
    width: "100%",
  },
  loadMoreText: {
    color: "blue",
    textDecoration: "underline",
    fontSize: 16,
  },
}));

const HomeScreen = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const filters = useRecoilValue(filtersState);
  const filtersCount = useRecoilValue(filtersCountState);
  const [localFiltersCount, setLocalFiltersCount] = useState(filtersCount);
  const [accommodations, setAccommodations] = useRecoilState(loadedAccommodations);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [pageState, setPageState] = useRecoilState(frontpageState);

  useEffect(() => {
    setLoading(true);

    getMoreAccommodations(true).then(() => {
      setLoading(false);
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLoading(true);
    setLocalFiltersCount(filtersCount);

    getMoreAccommodations(true).then(() => {
      setLoading(false);
    });

    // eslint-disable-next-line
  }, [filters]);

  const getMoreAccommodations = async (withoutTs: boolean) => {
    const result = await getAllAccommodationsQuery(filterAccomodations(filters, withoutTs ? 0 : accommodations.lastTs), 2000);
    const tempAcc = withoutTs ? [] : [...accommodations.accommodations];
    let ts = 0;
    let more = false;

    if (result.hasMoreResults()) {
      const query = await result.fetchNext();
      if (query.resources && query.resources.length > 0) {
        query.resources.forEach((obj) => {
          tempAcc.push(Accommodation.fromDbObject(obj));
        });

        ts = query.resources[query.resources.length - 1]._ts;
        more = query.hasMoreResults;
      }
    }

    setAccommodations({
      accommodations: [...tempAcc],
      lastTs: ts,
      hasMore: more,
    });
  };

  const toggleDrawer = () => {
    setDrawerOpen((prevState) => {
      return !prevState;
    });
  };

  const handleViewChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setPageState({ ...pageState, view: newValue });
  };

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const onAccommodationClick = (id: string, name: string) => {
    const url = createURLFriendlyString(name);
    navigate({
      pathname: "/accommodation/" + id + "/" + url,
    });
  };

  const onNewAccommodationClick = () => {
    navigate({
      pathname: `/accommodation/new`,
    });
  };

  const onProfileClick = () => {
    navigate("/profile");
  };

  return (
    <Fragment>
      <Header onNewAccommodationClick={onNewAccommodationClick} onProfileClick={onProfileClick} />
      <Box bgcolor="primary.main" color="#FFFFFF" className={classes.hero}>
        <Container maxWidth="xl">
          <Typography variant="h1">{t("welcome_text_heading")}</Typography>
          <p className={`p-ingress ${classes.heroText}`}>{t("welcome_text_ingress")}</p>
          <p className={classes.heroText}>{t("welcome_text_body")}</p>
        </Container>
        <Wave color="#ffffff" position="bottom" />
      </Box>
      <Container maxWidth="xl" className={classes.searchContainer}>
        <Typography variant="h2">{t("findAcc")}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={8}>
            <SearchSection open={drawerOpen} toggleDrawer={toggleDrawer} />
          </Grid>
          <Grid item xs={12} xl={4}>
            <Typography variant="subtitle2">{t("showAs")}</Typography>
            <Tabs value={pageState.view} onChange={handleViewChange} indicatorColor="primary" TabIndicatorProps={{ children: <span /> }}>
              <Tab iconPosition="start" label={t("map")} icon={<MapIcon />} disableRipple {...a11yProps(0)} />
              <Tab iconPosition="start" label={t("grid")} icon={<AppsIcon />} disableRipple {...a11yProps(1)} />
            </Tabs>
          </Grid>
        </Grid>
      </Container>
      <TabPanel value={pageState.view} index={0}>
        <Grid container className={classes.map}>
          <Grid item className={classes.mapContainer}>
            <MapComponent
              accommodations={accommodations.accommodations}
              onLabelClick={onAccommodationClick}
              forceLoading={localFiltersCount > 0 && loading}
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={pageState.view} index={1} className={classes.tabPanel}>
        <SortBySelector />
        <Container maxWidth="xl" className={classes.cardsContainer}>
          <AccommodationList accommodations={accommodations.accommodations} />
        </Container>
      </TabPanel>
      {toggleDrawer !== undefined && drawerOpen !== undefined ? <CustomDrawer open={drawerOpen} toggleDrawer={toggleDrawer} /> : null}
    </Fragment>
  );
};

export default HomeScreen;
