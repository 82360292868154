import { atom, selector } from "recoil";
import { $enum } from "ts-enum-util";
import { Region } from "../models/Types";

export const filtersState = atom({
  key: "filtersState",
  default: {
    general: "",
    sortBy: "alphabetical_asc",
    guests: [0, 5000],
    beds: [0, 500],
    floorSpaces: [0, 500],
    type: -1,
    regions: new Array<boolean>($enum(Region).length).fill(false),
    kitchen: false,
    bathroom: false,
    electricity: false,
    water: false,
    wifi: false,
    pets: false,
    party: false,
    parking: false,
    handicapAdapted: false,
    publicBeach: false,
    campfire: false,
    showers: false,
    sauna: false,
    publicTransportDistance: 0,
    groceryStoreDistance: 0,
  },
});

export const filtersCountState = selector({
  key: "filtersCountState",
  get: ({ get }) => {
    let count = 0;
    const filters = get(filtersState);

    if (filters.general.trim().length > 0) count++;
    if (filters.guests[0] > 0 || filters.guests[1] > 0) count++;
    if (filters.beds[0] > 0 || filters.beds[1] > 0) count++;
    if (filters.floorSpaces[0] > 0 || filters.floorSpaces[1] > 0) count++;
    if (filters.type > 0) count++;
    if (filters.regions.includes(true)) count++;
    if (filters.kitchen) count++;
    if (filters.bathroom) count++;
    if (filters.electricity) count++;
    if (filters.water) count++;
    if (filters.wifi) count++;
    if (filters.pets) count++;
    if (filters.party) count++;
    if (filters.parking) count++;
    if (filters.handicapAdapted) count++;
    if (filters.publicBeach) count++;
    if (filters.campfire) count++;
    if (filters.showers) count++;
    if (filters.sauna) count++;
    if (filters.publicTransportDistance > 0) count++;
    if (filters.groceryStoreDistance > 0) count++;

    return count;
  },
});
