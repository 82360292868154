import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import "./App.css";

// Screens
import HomeScreen from "./screens/HomeScreen";
import AccommodationScreen from "./screens/AccommodationScreen";
import ProfileScreen from "./screens/ProfileScreen";
import NewOrEditAccommodationScreen from "./screens/NewOrEditAccommodationScreen";

// Components
import ScrollToTop from "./components/ScrollToTop";
import LoginModal from "./components/modals/LoginModal";
import NewOrEditListingModal from "./components/modals/NewOrEditListingModal";
import ToggleActivationModal from "./components/modals/ToggleActivationModal";
import DeleteModal from "./components/modals/DeleteModal";
import ApproveModal from "./components/modals/ApproveModal";
import HardResetComponent from "./components/HardResetComponent";
import CoownersModal from "./components/modals/CoownersModal";

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ScrollToTop />
        <LoginModal />
        <NewOrEditListingModal />
        <ToggleActivationModal />
        <ApproveModal />
        <DeleteModal />
        <HardResetComponent />
        <CoownersModal />
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path="/accommodation/new" element={<NewOrEditAccommodationScreen />} />
          <Route path="/accommodation/:id/:name" element={<AccommodationScreen />} />
          <Route path="/accommodation/:id/:name/edit" element={<NewOrEditAccommodationScreen />} />
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
