import React from 'react';

interface Props{
  color: string,
  className?: string | undefined,
}

const Showers = (props: Props) => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 78.64 78.64" style={{width: '1.5rem'}} className={props.className}>
      <g>
        <path fill={props.color} d="M28.38,20.1l0.05-0.08c0.27-0.38,0.8-0.47,1.18-0.2l6.5,4.55c0.38,0.27,0.47,0.8,0.2,1.18l-0.05,0.08 c-0.27,0.38-0.8,0.47-1.18,0.2l-6.5-4.55C28.21,21.02,28.12,20.48,28.38,20.1L28.38,20.1z M60.91,89.12v28.25 c0,3.71-2.7,5.51-6,5.51h0c-3.3,0-6-1.8-6-5.51v-27.6h-3.6v27.6c0,3.71-2.7,5.51-6,5.51h0c-3.3,0-6-1.8-6-5.51V89.12 c-0.15-0.24-0.25-0.54-0.25-0.86V64.45l0-0.08l-16.8-8.53c-2.33-1.18-3.35-3.93-2.42-6.31l6.22-17.71c0.91-2.62,3.78-4,6.4-3.09 c2.62,0.91,4,3.78,3.09,6.4l-4.82,13.73l12.98,6.59c0.18,0.09,0.34,0.19,0.5,0.29c4.72-2.85,11.71-3.24,16.73-1.41l12.38-7.56 l-5.67-13.93c-1.04-2.58,0.2-5.51,2.78-6.56c2.58-1.04,5.51,0.2,6.56,2.78l7.28,17.88c0.91,2.24,0.08,4.88-2.05,6.18l-15.07,9.21 v25.93C61.16,88.57,61.07,88.87,60.91,89.12L60.91,89.12z M47.12,29.03c6.03,0,10.91,4.89,10.91,10.91 c0,6.03-4.89,10.91-10.91,10.91c-6.03,0-10.91-4.89-10.91-10.91C36.2,33.92,41.09,29.03,47.12,29.03L47.12,29.03z M0,10.28 c2.92-3.81,17.16-6.74,20.74-4.32c-2.12,3.71-1.85,7.48,1.16,11.33c-0.38,0.63-0.35,1.3,0.08,2.04l0.95,1.09 c0.38,0.38,0.85,0.42,1.46-0.08l14.45-14.6c0.39-0.47,0.32-0.87-0.14-1.24c-1.03-1.25-1.15-1.47-2.97-1.16 c-4.05-2.64-7.91-3.1-11.55-1.01C17.21-1.69,6.21-0.22,0,4.51V10.28L0,10.28z M36.57,13.08l0.05-0.08c0.27-0.38,0.8-0.47,1.18-0.2 l6.5,4.55c0.38,0.27,0.47,0.8,0.2,1.18l-0.05,0.08c-0.27,0.38-0.8,0.47-1.18,0.2l-6.5-4.55C36.39,13.99,36.3,13.46,36.57,13.08 L36.57,13.08z M40.31,9.33l0.05-0.08c0.27-0.38,0.8-0.47,1.18-0.2l6.5,4.55c0.38,0.27,0.47,0.8,0.2,1.18l-0.05,0.08 c-0.27,0.38-0.8,0.47-1.18,0.2l-6.5-4.55C40.14,10.24,40.05,9.71,40.31,9.33L40.31,9.33z M32.85,16.73l0.05-0.08 c0.27-0.38,0.8-0.47,1.18-0.2l6.5,4.55c0.38,0.27,0.47,0.8,0.2,1.18l-0.05,0.08c-0.27,0.38-0.8,0.47-1.18,0.2l-6.5-4.55 C32.67,17.64,32.58,17.11,32.85,16.73L32.85,16.73z"/>
      </g>
    </svg>
  )
}

export default Showers;