import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_en from "./translations/en/common_en.json"
import common_sv from "./translations/sv/common_sv.json"
import LanguageDetector from 'i18next-browser-languagedetector';

const options = {
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
}

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: options,
        resources: {
            en: {
                common: common_en
            },
            sv: {
                common: common_sv
            },
        },
        lng: "sv",
        fallbackLng: "sv",
        interpolation: { escapeValue: false }
    });

export default i18n;