import { BlobServiceClient } from "@azure/storage-blob";
import { imageResizer } from "./functions";

const getStorage = () => {
  const accountName = process.env.REACT_APP_STORAGE_ACCOUNT ?? "";
  const sasKey = process.env.REACT_APP_STORAGE_SAS ?? "";

  return new BlobServiceClient(`https://${accountName}.blob.core.windows.net${sasKey}`);
};

const getContainer = async () => {
  const containerClient = getStorage().getContainerClient("photos");
  return containerClient;
};

export const saveImages = async (id: string, images: Array<File>, withoutMain?: boolean): Promise<Array<string> | null> => {
  try {
    const containerClient = await getContainer();
    const result = new Array<string>();

    for (let i = 0; i < images.length; i++) {
      const image = await resizeImage(images[i]);
      if (typeof image !== "undefined") {
        const blob = containerClient.getBlockBlobClient(`${id}_${i.toString()}_${createDate()}`);
        const uploadBlobResponse = await blob.uploadBrowserData(image, {
          blobHTTPHeaders: {
            blobContentType: image.type,
          },
        });
        if (uploadBlobResponse._response.status === 201) {
          result.push(blob.url);
        } else {
          return null;
        }
      } else {
        return null;
      }
    }

    return result;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const deleteImages = async (images: Array<string>): Promise<boolean> => {
  try {
    const containerClient = await getContainer();

    for (let i = 0; i < images.length; i++) {
      const blob = containerClient.getBlockBlobClient(getImageFromUrl(images[i]));
      const deleteBlobResponse = await blob.delete();

      if (deleteBlobResponse._response.status !== 202) {
        return false;
      }
    }

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

const getImageFromUrl = (url: string): string => {
  const firstHalf = url.split("?")[0];
  const parts = firstHalf.split("/");

  return parts[parts.length - 1];
};

const createDate = () => {
  const date = Date.now();
  const ISOdate = new Date(date).toISOString().replaceAll(/[-:.zZ]/g, "");

  return ISOdate;
};

const resizeImage = async (file: File) => {
  try {
    const image = await imageResizer(file);
    return image;
  } catch (err) {
    console.log(err);
  }
};
