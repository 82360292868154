import { Container, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { FC, useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { filtersState } from "../store/filters_state";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  selection: {
    display: "flex",
    flexdirection: "row",
    justifyContent: "right",
    alignItems: "center",
    gap: "1rem",
    paddingTop: "1rem",
  },
  selector: {
    minWidth: "200px",
    margin: 0,
  },
}));

export const SortBySelector: FC = () => {
  const classes = useStyles();
  const [filters, setFilters] = useRecoilState(filtersState);
  const [formState, setFormState] = useState(filters);
  const [t] = useTranslation("common");

  useEffect(() => {
    setFormState(filters);
  }, [filters]);

  const handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof formState;
    const updatedState = {
      ...formState,
      [name]: event.target.value,
    };

    setFormState(updatedState);
    setFilters(updatedState);
  };

  return (
    <Container maxWidth="xl" className={classes.selection}>
      <Typography variant="h6">{t("sort_by")}</Typography>
      <FormControl variant="outlined">
        <Select
          name="sortBy"
          value={formState.sortBy}
          onChange={(e) => handleSelectChange(e as React.ChangeEvent<{ name?: string; value: unknown }>)}
          label={t("sort_by")}
          className={classes.selector}
        >
          <MenuItem value="alphabetical_asc">{t("alphabetical_asc")}</MenuItem>
          <MenuItem value="alphabetical_desc">{t("alphabetical_desc")}</MenuItem>
          <MenuItem value="date_desc">{t("date_desc")}</MenuItem>
          <MenuItem value="date_asc">{t("date_asc")}</MenuItem>
        </Select>
      </FormControl>
    </Container>
  );
};
