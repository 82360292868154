import { atom } from "recoil";
import User from "../models/User";

export const authUser = atom({
  key: "authUser",
  default: new User({
    id: "",
    username: "",
    isAdmin: false,
    listings: [],
  }),
});
