import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, CircularProgress, Button, Typography } from "@mui/material";
import Accommodation from "../models/Accommodation";
import { useRecoilState } from "recoil";
import { frontpageState } from "../store/frontpage_state";

// Components
import AccommodationCard from "../components/AccommodationCard";
import useWindowDimensions from "../custom_hooks/useWindowDimensions";

interface Props {
  accommodations: Accommodation[];
}

const AccommodationList = (props: Props) => {
  const [t] = useTranslation("common");
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [accList, setAccList] = useState<Accommodation[]>([]);
  const [pagesCount, setPagesCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [pageState, setPageState] = useRecoilState(frontpageState);

  useEffect(() => {
    setLoading(true);

    if (width < 1199) {
      setItemsPerPage(10);
    }

    if (props.accommodations) {
      createPages(props.accommodations);
      createAccommodationList(props.accommodations);
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [props.accommodations, width]);

  useEffect(() => {
    setLoading(true);
    createAccommodationList(props.accommodations);
    setLoadingMore(false);
    setLoading(false);

    // eslint-disable-next-line
  }, [pageState.page]);

  const createPages = (accommodationsList: Accommodation[]) => {
    const numOfPages = Math.ceil(accommodationsList.length / itemsPerPage);

    setPagesCount(numOfPages);
  };

  const createAccommodationList = (accommodationsList: Accommodation[]) => {
    const endPoint = (pageState.page + 1) * itemsPerPage;
    const newList = [];

    for (let i = 0; i < endPoint; i++) {
      const accommodation = accommodationsList[i];

      if (accommodation !== undefined) {
        newList.push(accommodation);
      }
    }

    setAccList([...newList]);
  };

  const onLoadMore = () => {
    setLoadingMore(true);
    setPageState({ ...pageState, page: pageState.page + 1 });
  };

  const loadingScreen = (
    <Grid container justifyContent="center" alignItems="center">
      <CircularProgress />
    </Grid>
  );

  const hasAccommodations = accList.length > 0;
  const hasMoreAccommodations = pageState.page !== pagesCount - 1 && hasAccommodations;

  return (
    <Fragment>
      <Grid container spacing={4} style={{ marginBottom: "1rem" }}>
        {!loading
          ? accList.map((accommodation) => (
              <Grid item xs={12} md={6} xl={4} key={accommodation.id}>
                <AccommodationCard accommodation={accommodation} />
              </Grid>
            ))
          : loadingScreen}
        {!loading && pageState.page !== pagesCount - 1 && (
          <Grid
            item
            xs={12}
            style={{
              width: "100%",
              marginTop: 10,
              marginBottom: 20,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {loadingMore ? (
              <CircularProgress />
            ) : (
              hasMoreAccommodations && (
                <Button variant="outlined" color="primary" size="small" disableRipple onClick={onLoadMore}>
                  {t("load_more")}
                </Button>
              )
            )}
          </Grid>
        )}
        {!hasAccommodations && (
          <Typography variant="h3" component="p" color="textPrimary">
            {t("no_accommodations")}
          </Typography>
        )}
      </Grid>
    </Fragment>
  );
};

export default AccommodationList;
