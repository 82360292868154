import React from 'react';

interface Props{
  color: string,
  className?: string | undefined,
}

const Sauna = (props: Props) => {
  return (
    <svg x="0px" y="0px" viewBox="57 58 135 135" style={{width: '1.5rem'}} className={props.className}>
        <path fill={props.color} d="M 186 172.1 l -17 -27.4 c -1.9 -3.1 -4.4 -3.8 -7.9 -4.7 c -0.1 0 -0.2 0 -0.3 0 l -25.4 -3.4 l -10.2 -25.7 c 0 -0.1 -0.1 -0.2 -0.1 -0.2 c -1 -2 -2.3 -3.7 -4 -5 c 5 -2.5 8.4 -7.6 8.4 -13.5 c 0 -8.3 -6.8 -15.1 -15.1 -15.1 s -15.1 6.8 -15.1 15.1 c 0 4.3 1.8 8.2 4.7 11 c -0.3 0.1 -0.7 0.3 -1 0.5 c -3.6 1.9 -6.4 5 -7.8 8.9 l -18.6 33.5 c -2.1 3.9 -2.4 6.7 -1.9 8.7 h -9.6 c -1.5 0 -2.7 1.2 -2.7 2.7 c 0 1.5 1.2 2.7 2.7 2.7 h 16.3 c 0.3 0 0.6 0 0.9 0 h 40.8 c 0 0 0 0 0 0 c 0.2 0 0.5 0 0.7 0 h 30.9 l 14.1 22.7 c 1.8 3 5 4.7 8.5 4.7 c 1.9 0 3.7 -0.5 5.3 -1.5 c 2.3 -1.4 3.9 -3.6 4.5 -6.3 C 187.8 177.1 187.4 174.4 186 172.1 z z M 90.3 154.6 l 11.7 -17.2 l 7 13.8 c 0.6 1.3 1.4 2.4 2.4 3.4 H 90.3 z H 124 z"/>
        <path fill={props.color} d="M153.2,107.2c-0.4,1.5,0.5,3,2,3.3c0.2,0.1,0.4,0.1,0.6,0.1c1.2,0,2.3-0.8,2.7-2.1c2.7-11.4,0.7-17.4-1.1-22.7
          c-1.7-5.1-3.3-9.9-0.8-19.9c0.4-1.5-0.5-3-2-3.3c-1.5-0.4-2.9,0.5-3.3,2c-2.9,11.5-1,17.3,0.9,23C154,92.6,155.6,97.4,153.2,107.2z
          "/>
        <path fill={props.color} d="M166.7,107.2c-0.4,1.5,0.5,3,2,3.3c0.2,0.1,0.4,0.1,0.6,0.1c1.2,0,2.3-0.8,2.7-2.1c2.7-11.4,0.7-17.4-1.1-22.7
          c-1.7-5.1-3.3-9.9-0.8-19.9c0.4-1.5-0.5-3-2-3.3c-1.5-0.4-2.9,0.5-3.3,2c-2.9,11.5-1,17.3,0.9,23
          C167.5,92.6,169.1,97.4,166.7,107.2z"/>
        <path fill={props.color} d="M180.2,107.2c-0.4,1.5,0.5,3,2,3.3c0.2,0.1,0.4,0.1,0.6,0.1c1.2,0,2.3-0.8,2.7-2.1c2.7-11.4,0.7-17.4-1.1-22.7
          c-1.7-5.1-3.3-9.9-0.8-19.9c0.4-1.5-0.5-3-2-3.3c-1.5-0.4-2.9,0.5-3.3,2c-2.9,11.5-1,17.3,0.9,23
          C180.9,92.6,182.6,97.4,180.2,107.2z"/>
    </svg>
  )
}

export default Sauna;