import { atom } from "recoil";

export const frontpageState = atom<{
  page: number;
  view: number;
}>({
  key: "frontpageState",
  default: {
    page: 1,
    view: 0,
  },
});
