import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import User from "../../models/User";
import { modalsState } from "../../store/modals_state";
import { addListingToUser, getUsers } from "../../utils/database";

const useStyles = makeStyles({
  inputWithButton: {
    minWidth: 270,
  },
  chip: {
    marginLeft: 5,
    marginBottom: 5,
  },
  chipContainer: {
    marginTop: 40,
  },
});

const CoownersModal = () => {
  const [modals, setModals] = useRecoilState(modalsState);
  const [loading, setLoading] = useState(false);
  const [owners, setOwners] = useState<Array<User>>([]);
  const [allUsers, setAllUsers] = useState<Array<User>>([]);
  const [error, setError] = useState(false);
  const [newOwner, setNewOwner] = useState("");
  const classes = useStyles();
  const [t] = useTranslation("common");

  useEffect(() => {
    if (modals.coownersModal !== "") {
      setLoading(true);
      getUsers().then((usersResult) => {
        const allUsersResult = usersResult.map((user) => {
          return new User(user);
        });
        const owners = allUsersResult.filter((user) => user.listings.includes(modals.coownersModal));

        setAllUsers(allUsersResult);
        setOwners(owners);
        setLoading(false);
      });
    }
  }, [modals.coownersModal]);

  const ownersChips = () => {
    return owners.map((owner) => <Chip key={owner.id} label={owner.id} color="primary" className={classes.chip} />);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNewOwner(event.target.value);
  };

  const handleClose = () => {
    setError(false);
    setNewOwner("");
    setModals({
      ...modals,
      coownersModal: "",
    });
  };

  const onOwnerAdd = async () => {
    if (newOwner.trim() === "") {
      setError(true);
      return;
    }

    let user = allUsers.find((user) => user.id === newOwner);

    if (!user) {
      user = allUsers.find((user) => user.username === newOwner);
    }

    if (user) {
      setNewOwner("");
      setLoading(true);
      await addListingToUser(user.id, modals.coownersModal);

      setOwners([...owners, user]);
      setError(false);
      setLoading(false);
    } else {
      setError(true);
    }
  };

  return (
    <Dialog
      open={modals.coownersModal !== ""}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("add_co_owners")}</DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid container alignItems="center" justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <Fragment>
            <Grid container direction="row" justifyContent="flex-start" style={{ marginBottom: 10 }} className={classes.chipContainer}>
              {ownersChips()}
            </Grid>
            <FormControl className={classes.inputWithButton} error={error}>
              <InputLabel>{t("add_co_owners")}</InputLabel>
              <Input style={{ marginBottom: 10 }} type="string" name="more" value={newOwner} onChange={onInputChange} />
              {error ? <FormHelperText>{"No user found"}</FormHelperText> : null}
            </FormControl>
            <Grid container direction="row" justifyContent="flex-end" style={{ marginBottom: 40 }}>
              <Button color="primary" variant="contained" onClick={onOwnerAdd}>
                {t("add")}
              </Button>
            </Grid>
          </Fragment>
        )}
      </DialogContent>
      {!loading && (
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CoownersModal;
