import React, { useState, useEffect } from "react";
import {
  SwipeableDrawer,
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Theme,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { filtersState } from "../store/filters_state";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { AccommodationType, accommodationTypeToString } from "../models/Types";
import { $enum } from "ts-enum-util";
import "rc-slider/assets/index.css";
import Slider from "rc-slider";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const TSlider = createSliderWithTooltip(Slider);

interface Props {
  open: boolean;
  toggleDrawer: Function;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: 400,
    padding: "2rem 3rem",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      padding: "2rem 2rem",
    },
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 10,
  },
  subtitle: {
    textAlign: "left",
    fontWeight: "bold",
    marginBottom: 20,
  },
  selection: {
    marginBottom: 20,
  },
  button: {
    width: 200,
  },
}));

const CustomDrawer = (props: Props) => {
  const classes = useStyles();
  const [filters, setFilters] = useRecoilState(filtersState);
  const [formState, setFormState] = useState(filters);
  const [t] = useTranslation("common");
  const theme = useTheme();
  const rcSliderStyles = {
    sliderContainer: {
      marginBottom: "2.5rem",
    },
    sliderRail: {
      backgroundColor: theme.palette.primary.light,
      opacity: 0.3,
      height: 8,
    },
    sliderTrack: {
      backgroundColor: theme.palette.primary.main,
      height: 8,
    },
    sliderHandle: {
      height: 24,
      width: 24,
      marginTop: -8,
      border: `2px solid ${theme.palette.primary.main}`,
    },
    sliderMarks: {
      color: theme.palette.text.primary,
      fontSize: "1rem",
      marginTop: "0.5rem",
    },
    sliderDot: {
      bottom: -4,
    },
  };

  useEffect(() => {
    setFormState(filters);
  }, [filters]);

  const toggleDrawer = (event: React.MouseEvent) => {
    setFormState(filters);
    props.toggleDrawer();
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name.includes("region")) {
      let index = +event.target.name.split("/")[1];
      let newRegions = [...formState.regions];
      newRegions[index] = event.target.checked;

      setFormState({
        ...formState,
        regions: [...newRegions],
      });
    } else {
      setFormState({
        ...formState,
        [event.target.name]: event.target.checked,
      });
    }
  };

  const handleSelectionChange = (event: React.ChangeEvent<{ value: unknown; name?: string }>, child: React.ReactNode) => {
    setFormState({ ...formState, [event.target.name ?? ""]: event.target.value as number });
  };

  const onClear = () => {
    setFilters({
      ...filters,
      guests: [0, 5000],
      beds: [0, 500],
      floorSpaces: [0, 500],
      type: -1,
      kitchen: false,
      bathroom: false,
      electricity: false,
      water: false,
      wifi: false,
      pets: false,
      party: false,
      parking: false,
      handicapAdapted: false,
      publicBeach: false,
      campfire: false,
      showers: false,
      sauna: false,
      publicTransportDistance: 0,
      groceryStoreDistance: 0,
    });

    props.toggleDrawer();
  };

  const handleSliderChange = (key: string, newValue: number | number[]) => {
    setFormState({ ...formState, [key]: newValue });
  };

  const onSearch = () => {
    setFilters(formState);
    props.toggleDrawer();
  };

  const marksSlider = {
    0: {
      style: rcSliderStyles.sliderMarks,
      label: "0km",
    },
    50: {
      style: rcSliderStyles.sliderMarks,
      label: "50km",
    },
  };
  const marksRange = {
    0: {
      style: rcSliderStyles.sliderMarks,
      label: "0",
    },
    500: {
      style: rcSliderStyles.sliderMarks,
      label: "500",
    },
  };

  const guestsMarksRange = {
    0: {
      style: rcSliderStyles.sliderMarks,
      label: "0",
    },
    5000: {
      style: rcSliderStyles.sliderMarks,
      label: "5000",
    },
  };

  return (
    <SwipeableDrawer open={props.open} anchor="left" onClose={toggleDrawer} onOpen={toggleDrawer}>
      <Grid className={classes.main}>
        <Typography variant="h4">{t("filter_by")}:</Typography>

        <FormGroup>
          <FormControl variant="outlined" className={classes.selection}>
            <InputLabel>{t("type_of_accommodation")}</InputLabel>
            <Select
              name="type"
              value={formState.type}
              onChange={(e, c) => handleSelectionChange(e as React.ChangeEvent<{ value: unknown; name?: string }>, c)}
              label={t("type_of_accommodation")}
            >
              <MenuItem value={-1}>
                <em>{t("no_selection")}</em>
              </MenuItem>
              {$enum(AccommodationType).map((key) => {
                return (
                  <MenuItem key={key} value={key}>
                    {t(accommodationTypeToString(key))}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <div style={rcSliderStyles.sliderContainer}>
            <Typography variant="subtitle2" gutterBottom>
              {t("no_of_guests")}
            </Typography>
            <Range
              step={10}
              max={5000}
              value={formState.guests}
              onChange={(value: Array<number>) => handleSliderChange("guests", value)}
              defaultValue={[0, 5000]}
              railStyle={rcSliderStyles.sliderRail}
              trackStyle={[rcSliderStyles.sliderTrack]}
              handleStyle={[rcSliderStyles.sliderHandle]}
              dotStyle={rcSliderStyles.sliderDot}
              marks={guestsMarksRange}
            />
          </div>
          <div style={rcSliderStyles.sliderContainer}>
            <Typography variant="subtitle2" gutterBottom>
              {t("beds")}
            </Typography>
            <Range
              step={10}
              max={500}
              value={formState.beds}
              defaultValue={[0, 500]}
              onChange={(value: Array<number>) => handleSliderChange("beds", value)}
              railStyle={rcSliderStyles.sliderRail}
              trackStyle={[rcSliderStyles.sliderTrack]}
              handleStyle={[rcSliderStyles.sliderHandle]}
              dotStyle={rcSliderStyles.sliderDot}
              marks={marksRange}
            />
          </div>
          <div style={rcSliderStyles.sliderContainer}>
            <Typography variant="subtitle2" gutterBottom>
              {t("floor_spaces")}
            </Typography>
            <Range
              step={10}
              max={500}
              value={formState.floorSpaces}
              defaultValue={[0, 500]}
              onChange={(value: Array<number>) => handleSliderChange("floorSpaces", value)}
              railStyle={rcSliderStyles.sliderRail}
              trackStyle={[rcSliderStyles.sliderTrack]}
              handleStyle={[rcSliderStyles.sliderHandle]}
              dotStyle={rcSliderStyles.sliderDot}
              marks={marksRange}
            />
          </div>
          <div style={rcSliderStyles.sliderContainer}>
            <Typography variant="subtitle2" gutterBottom>
              {t("distance_to_public_transport")}
            </Typography>
            <TSlider
              step={1}
              max={50}
              value={formState.publicTransportDistance}
              onChange={(value: number) => handleSliderChange("publicTransportDistance", value)}
              tipFormatter={(value: {
                toString: () => boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
              }) => <span className="tooltip">{value.toString()}</span>}
              railStyle={rcSliderStyles.sliderRail}
              trackStyle={rcSliderStyles.sliderTrack}
              handleStyle={rcSliderStyles.sliderHandle}
              dotStyle={rcSliderStyles.sliderDot}
              marks={marksSlider}
            />
          </div>
          <div style={rcSliderStyles.sliderContainer}>
            <Typography variant="subtitle2" gutterBottom>
              {t("distance_to_grocery_store")}
            </Typography>
            <TSlider
              step={1}
              max={50}
              value={formState.groceryStoreDistance}
              onChange={(value: number) => handleSliderChange("groceryStoreDistance", value)}
              tipFormatter={(value: {
                toString: () => boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
              }) => <span className="tooltip">{value.toString()}</span>}
              railStyle={rcSliderStyles.sliderRail}
              trackStyle={rcSliderStyles.sliderTrack}
              handleStyle={rcSliderStyles.sliderHandle}
              dotStyle={rcSliderStyles.sliderDot}
              marks={marksSlider}
            />
          </div>
          <FormControlLabel
            control={<Checkbox checked={formState.kitchen} onChange={handleCheckboxChange} name="kitchen" color="primary" />}
            label={t("kitchen")}
          />
          <FormControlLabel
            control={<Checkbox checked={formState.bathroom} onChange={handleCheckboxChange} name="bathroom" color="primary" />}
            label={t("bathroom")}
          />
          <FormControlLabel
            control={<Checkbox checked={formState.showers} onChange={handleCheckboxChange} name="showers" color="primary" />}
            label={t("showers")}
          />
          <FormControlLabel
            control={<Checkbox checked={formState.electricity} onChange={handleCheckboxChange} name="electricity" color="primary" />}
            label={t("electricity")}
          />
          <FormControlLabel
            control={<Checkbox checked={formState.water} onChange={handleCheckboxChange} name="water" color="primary" />}
            label={t("water")}
          />
          <FormControlLabel
            control={<Checkbox checked={formState.wifi} onChange={handleCheckboxChange} name="wifi" color="primary" />}
            label={t("wifi")}
          />
          <FormControlLabel
            control={<Checkbox checked={formState.sauna} onChange={handleCheckboxChange} name="sauna" color="primary" />}
            label={t("sauna")}
          />
          <FormControlLabel
            control={<Checkbox checked={formState.pets} onChange={handleCheckboxChange} name="pets" color="primary" />}
            label={t("pets_allowed")}
          />
          <FormControlLabel
            control={<Checkbox checked={formState.party} onChange={handleCheckboxChange} name="party" color="primary" />}
            label={t("party_allowed")}
          />
          <FormControlLabel
            control={<Checkbox checked={formState.parking} onChange={handleCheckboxChange} name="parking" color="primary" />}
            label={t("parking")}
          />
          <FormControlLabel
            control={
              <Checkbox checked={formState.handicapAdapted} onChange={handleCheckboxChange} name="handicapAdapted" color="primary" />
            }
            label={t("handicap_adapted")}
          />
          <FormControlLabel
            control={<Checkbox checked={formState.publicBeach} onChange={handleCheckboxChange} name="publicBeach" color="primary" />}
            label={t("public_beach")}
          />
          <FormControlLabel
            control={<Checkbox checked={formState.campfire} onChange={handleCheckboxChange} name="campfire" color="primary" />}
            label={t("campfire")}
          />
        </FormGroup>
        <Grid container spacing={2} style={{ marginTop: 30 }}>
          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" onClick={onSearch} disableElevation fullWidth>
              {t("search")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="secondary" onClick={onClear} disableElevation fullWidth>
              {t("clear_filters")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
};

export default CustomDrawer;
