import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { useRecoilState, useSetRecoilState } from "recoil";
import { modalsState } from "../../store/modals_state";
import { useTranslation } from "react-i18next";
import { hardReset } from "../../store/hard_reset";

const NewOrEditListingModal = () => {
  const [modals, setModals] = useRecoilState(modalsState);
  const [t] = useTranslation("common");
  const setHardReset = useSetRecoilState(hardReset);

  const handleClose = () => {
    setHardReset(true);
    setModals({
      ...modals,
      newOrEditListingModal: "",
    });
  };

  return (
    <Dialog
      open={modals.newOrEditListingModal !== ""}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{modals.newOrEditListingModal === "edit" ? t("edited_listing") : t("new_listing")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {modals.newOrEditListingModal === "edit" ? t("edited_listing_message") : t("new_listing_message")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewOrEditListingModal;
