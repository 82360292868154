import { atom } from "recoil";
import Accommodation from "../models/Accommodation";

interface ModalsState {
  newOrEditListingModal: string;
  loginModal: boolean;
  toggleActivationModal: Accommodation | null;
  approveModal: Accommodation | null;
  deleteModal: string;
  coownersModal: string;
}

const defaultState: ModalsState = {
  newOrEditListingModal: "",
  loginModal: false,
  toggleActivationModal: null,
  approveModal: null,
  deleteModal: "",
  coownersModal: "",
};

export const modalsState = atom({
  key: "modalsState",
  default: defaultState,
});
