import React, { useEffect, useState } from "react";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Dialog, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  open: boolean;
  toggleModal: Function;
  images?: Array<string>;
  startImage?: number;
}

const GalleryModal = (props: Props) => {
  const [images, setImages] = useState<Array<{}>>([]);

  useEffect(() => {
    configureImages();

    // eslint-disable-next-line
  }, []);

  const configureImages = () => {
    const images = props.images;
    if (images) {
      const renderItem = (source: string) => (
        <img style={{ height: "75vh", maxWidth: "90vw", objectFit: "contain" }} src={source} alt="Not found" />
      );
      const renderThumbInner = (source: string) => (
        <img style={{ height: 60, width: 60, objectFit: "cover" }} src={source} alt="Not found" />
      );
      const photosToObjects: any[] = [];

      images.forEach((photo) => {
        photosToObjects.push({
          original: photo,
          thumbnail: photo,
          renderItem: () => renderItem(photo),
          renderThumbInner: () => renderThumbInner(photo),
        });
      });

      setImages(photosToObjects);
    }
  };

  const toggleModal = (event: React.MouseEvent) => {
    props.toggleModal();
  };

  return (
    <Dialog open={props.open} onClose={toggleModal} fullWidth={true} maxWidth="lg">
      <Button color="primary" onClick={toggleModal} style={{ position: "absolute", right: 0, top: 5, zIndex: 99 }}>
        <CloseIcon />
      </Button>
      <ImageGallery
        showPlayButton={false}
        showFullscreenButton={false}
        items={images as ReactImageGalleryItem[]}
        startIndex={props.startImage}
      />
    </Dialog>
  );
};

export default GalleryModal;
