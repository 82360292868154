export enum AccommodationType {
  Scoutcabin,
  Campsite,
  Scoutrooms,
  Windshelter,
}

export enum ToiletType {
  WC,
  TC,
}

export enum WaterType {
  None,
  Running_Cold,
  Running_Hot_And_Cold,
  Only_Dishwasher,
}

export enum Region {
  Blekinge,
  Dalarnas,
  Gotlands,
  Gävleborgs,
  Hallands,
  Jämtlands,
  Jönköpings,
  Kalmar,
  Kronobergs,
  Norrbottens,
  Skåne,
  Stockholms,
  Södermanlands,
  Uppsala,
  Värmlands,
  Västerbottens,
  Västernorrlands,
  Västmanlands,
  Västra_Götalands,
  Örebro,
  Östergötlands,
}

export const accommodationTypeToString = (type: AccommodationType): string => {
  switch (type) {
    case AccommodationType.Scoutcabin:
      return "scoutcabin";
    case AccommodationType.Campsite:
      return "campsite";
    case AccommodationType.Scoutrooms:
      return "scoutrooms";
    case AccommodationType.Windshelter:
      return "windshelter";
  }
};

export const toiletTypeToString = (type: ToiletType): string => {
  switch (type) {
    case ToiletType.WC:
      return "WC";
    case ToiletType.TC:
      return "TC";
  }
};

export const waterTypeToString = (type: WaterType): string => {
  switch (type) {
    case WaterType.None:
      return "no_water";
    case WaterType.Running_Cold:
      return "running_cold_water";
    case WaterType.Running_Hot_And_Cold:
      return "running_hot_and_cold_water";
    case WaterType.Only_Dishwasher:
      return "only_dishwasher";
  }
};

export const regionToString = (region: Region): string => {
  switch (region) {
    case Region.Blekinge:
      return "Blekinge län";
    case Region.Dalarnas:
      return "Dalarnas län";
    case Region.Gotlands:
      return "Gotlands län";
    case Region.Gävleborgs:
      return "Gävleborgs län";
    case Region.Hallands:
      return "Hallands län";
    case Region.Jämtlands:
      return "Jämtlands län";
    case Region.Jönköpings:
      return "Jönköpings län";
    case Region.Kalmar:
      return "Kalmar län";
    case Region.Kronobergs:
      return "Kronobergs län";
    case Region.Norrbottens:
      return "Norrbottens län";
    case Region.Skåne:
      return "Skåne län";
    case Region.Stockholms:
      return "Stockholms län";
    case Region.Södermanlands:
      return "Södermanlands län";
    case Region.Uppsala:
      return "Uppsala län";
    case Region.Värmlands:
      return "Värmlands län";
    case Region.Västerbottens:
      return "Västerbottens län";
    case Region.Västernorrlands:
      return "Västernorrlands län";
    case Region.Västmanlands:
      return "Västmanlands län";
    case Region.Västra_Götalands:
      return "Västra Götalands län";
    case Region.Örebro:
      return "Örebro län";
    case Region.Östergötlands:
      return "Östergötlands län";
  }
};
