import React from "react";
import { Grid } from "@mui/material";
import AccommodationScreen from "../../screens/AccommodationScreen";

const StepFive = () => {
  return (
    <Grid>
      <AccommodationScreen preview />
    </Grid>
  );
};

export default StepFive;
