import { atom } from "recoil";
import Accommodation from "../models/Accommodation";

export const loadedAccommodations = atom<{
  accommodations: Array<Accommodation>;
  lastTs: number;
  hasMore: boolean;
}>({
  key: "loadedAccommodations",
  default: {
    accommodations: [],
    lastTs: 0,
    hasMore: true,
  },
});
