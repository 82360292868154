import React from "react";
import { Card, CardMedia, CardContent, Typography, Grid, Button, Theme, Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Accommodation from "../models/Accommodation";
import AmenitiesSection from "./AmenitiesSection";
import { accommodationTypeToString, regionToString } from "../models/Types";
import { useTranslation } from "react-i18next";
import PlaceIcon from "@mui/icons-material/Place";
import { Link, useNavigate } from "react-router-dom";
import { createURLFriendlyString } from "../utils/functions";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    marginTop: 25,
    marginBottom: 25,
  },
  card: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    background: "#ffffff",
    position: "relative",
  },
  cardMedia: {
    width: "100%",
    height: "50%",
    cursor: "pointer",
  },
  clickableHeading: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  cardContent: {
    position: "relative",
  },
  actionArea: {
    height: "100%",
  },
  listingState: {
    height: 50,
    padding: "0 1rem",
    color: "#ffffff",
    position: "absolute",
    top: "1rem",
    left: "1rem",
  },
  placeText: {
    display: "flex",
    alignItems: "center",
  },
  typeChip: {
    position: "absolute",
    top: "-18px",
    right: "10px",
  },
  link: {
    textDecoration: "none",
  },
}));

interface Props {
  accommodation: Accommodation;
}

const AccommodationCard = (props: Props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [t] = useTranslation("common");
  const theme = useTheme();

  const getAccommodationUrl = (): string => {
    const { name, id } = props.accommodation;
    const url = createURLFriendlyString(name);
    return "/accommodation/" + id + "/" + url;
  };

  const onImageClick = () => {
    navigate({
      pathname: getAccommodationUrl(),
    });
  };

  return (
    <React.Fragment>
      <Card className={classes.card} elevation={0}>
        {(props.accommodation.deactivated || !props.accommodation.approved) && (
          <Card
            className={classes.listingState}
            style={{
              backgroundColor: props.accommodation.deactivated ? theme.palette.error.main : theme.palette.secondary.main,
            }}
            elevation={15}
          >
            <Grid container style={{ width: "100%", height: "100%" }} alignItems="center" justifyContent="center">
              <Typography variant="body2" style={{ textAlign: "center", fontWeight: "bold" }}>
                {props.accommodation.deactivated ? "Deactivated" : "Pending approval"}
              </Typography>
            </Grid>
          </Card>
        )}

        <CardMedia className={classes.cardMedia} component="img" src={props.accommodation.mainPhoto} onClick={onImageClick} />
        <CardContent className={classes.cardContent}>
          <Chip label={t(accommodationTypeToString(props.accommodation.type))} color="primary" className={classes.typeChip} />
          <Link to={getAccommodationUrl()} className={classes.link}>
            <Typography variant="h3" color="primary" className={classes.clickableHeading}>
              {props.accommodation.name}
            </Typography>
          </Link>
          <p className={classes.placeText}>
            <PlaceIcon color="primary" fontSize="inherit" /> {regionToString(props.accommodation.region)}
          </p>
          <AmenitiesSection accommodation={props.accommodation} showShortList={true} />
          <Link to={getAccommodationUrl()} className={classes.link}>
            <Button color="primary">Läs mer</Button>
          </Link>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default AccommodationCard;
