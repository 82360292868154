import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Theme,
  Grid,
  Typography,
  Container,
  Card,
  CardActionArea,
  CardMedia,
  ImageList,
  ImageListItem,
  Box,
  Chip,
  Button,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useRecoilState, useRecoilValue } from "recoil";

// Components
import Header from "../components/Header";
import AmenitiesSection from "../components/AmenitiesSection";
import InformationSection from "../components/InformationSection";
import MapComponent from "../components/MapComponent";
import FloatingButton from "../components/FloatingButton";
import NoUserFallback from "../components/NoUserFallback";
import GalleryModal from "../components/modals/GalleryModal";

// Icons
import PlaceIcon from "@mui/icons-material/Place";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

// Store
import { authUser } from "../store/auth_user";
import { mapData } from "../store/map_data";
import { newListingState } from "../store/new_listing_state";
import { loadedAccommodations } from "../store/loaded_accommodations";
import { modalsState } from "../store/modals_state";
import { hardReset } from "../store/hard_reset";

// Utils
import { getAccommodation } from "../utils/database";
import { findAccommodation } from "../utils/functions";

// Models
import { accommodationTypeToString, regionToString } from "../models/Types";
import Accommodation from "../models/Accommodation";
import Linkify from "linkify-react";

const useStyles = makeStyles((theme: Theme) => ({
  photoContainer: {
    width: "100%",
    position: "relative",
    marginBottom: 20,
  },
  mainPhoto: {
    width: "100%",
  },
  typeChip: {
    position: "absolute",
    left: 10,
    bottom: -10,
  },
  nameText: {
    fontSize: 28.0,
    fontWeight: "bold",
  },
  descriptionText: {
    fontSize: 20.0,
    whiteSpace: "pre-line",
  },
  sectionText: {
    fontSize: 24.0,
    fontWeight: "bold",
    marginBottom: 6,
  },
  map: {
    width: "100%",
    height: 500,
  },
  accommodationContainer: {
    color: "#444547",
    paddingBottom: 20,
  },
  infoBox: {
    background: theme.palette.primary.main,
    color: "#ffffff",
    padding: "2rem",
  },
  directionsBox: {
    background: "#E6E6DD",
    padding: "2rem",
  },
  profileAvatar: {
    width: "100px",
    height: "133px",
    borderRadius: "10px",
    background: "#E6E6DD",
    overflow: "hidden",
    display: "flex",
    alignItems: "flex-end",
  },
  accommodationType: {
    marginBottom: "0.25rem",
  },
  placeText: {
    display: "flex",
    alignItems: "center",
    marginBottom: "2rem",
  },
}));

interface Props {
  preview?: boolean;
}

const AccommodationScreen = (props: Props) => {
  const classes = useStyles();
  const listingState = useRecoilValue(newListingState);
  const [accommodation, setAccommodation] = useState<Accommodation | null>(
    props.preview ? Accommodation.fromNewListingObject(listingState) : null
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const user = useRecoilValue(authUser);
  const mapAccommodations = useRecoilValue(mapData);
  const homeAccommodations = useRecoilValue(loadedAccommodations);
  const [modals, setModals] = useRecoilState(modalsState);
  const reset = useRecoilValue(hardReset);
  const [t] = useTranslation("common");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [clickedImage, setClickedImage] = useState(0);
  const [photoList, setPhotoList] = useState<string[]>([]);
  const params = useParams() as { id: string; name: string };

  useEffect(() => {
    if (reset) {
      setAccommodation(null);
      return;
    }

    if (!accommodation) {
      let tempAccommodation = findAccommodation(params.id, [
        ...mapAccommodations.readyAccommodations,
        ...homeAccommodations.accommodations,
      ]);
      if (!tempAccommodation) {
        setLoading(true);

        getAccommodation(params.id).then((result) => {
          if (!result) {
            goBack();
          } else {
            const accommodation = Accommodation.fromDbObject(result);
            const photos = [accommodation.mainPhoto, ...accommodation.photos];

            setAccommodation(accommodation);
            setPhotoList(photos);
            setLoading(false);
          }
        });
      } else {
        setAccommodation(tempAccommodation);
        const photos = [tempAccommodation.mainPhoto, ...tempAccommodation.photos];
        setPhotoList(photos);
      }
    }
    // eslint-disable-next-line
  }, [reset]);

  const onImageClick = (index: number) => {
    if (!props.preview) {
      setClickedImage(index);
      toggleGallery();
    }
  };

  const floatingButtonOption = (option: string) => {
    switch (option) {
      case "profile":
        navigate("/profile");
        break;
      case "edit":
        if (accommodation) {
          navigate({
            pathname: `/accommodation/${accommodation.id}/${params.name}/edit`,
          });
        }
        break;
      case "add":
        navigate({
          pathname: `/accommodation/new`,
        });
        break;
      case "activate_deactivate":
        setModals({
          ...modals,
          toggleActivationModal: accommodation,
        });
        break;
      case "approve":
        setModals({
          ...modals,
          approveModal: accommodation,
        });
        break;
      case "delete":
        if (accommodation) {
          setModals({
            ...modals,
            deleteModal: accommodation.id,
          });
        }
        break;
      case "addOwners":
        if (accommodation) {
          setModals({
            ...modals,
            coownersModal: accommodation.id,
          });
        }
        break;
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const onProfileClick = () => {
    navigate("/profile");
  };

  const onNewAccommodationClick = () => {
    navigate({
      pathname: `/accommodation/new`,
    });
  };

  const toggleGallery = () => {
    setGalleryOpen((prevState) => {
      return !prevState;
    });
  };

  if (loading) {
    return (
      <Grid container alignItems="center" justifyContent="center" style={{ marginTop: 30 }}>
        <CircularProgress />
      </Grid>
    );
  }

  return accommodation ? (
    accommodation.deactivated && !user.isAdmin ? (
      <NoUserFallback />
    ) : (
      <Fragment>
        <Header onProfileClick={onProfileClick} onNewAccommodationClick={onNewAccommodationClick} />
        <Container maxWidth="xl" className={classes.accommodationContainer}>
          <Grid container spacing={6}>
            {props.preview ? null : (
              <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <Button color="primary" onClick={goBack}>
                  <ArrowBackIosIcon /> {t("go_back")}
                </Button>
              </Grid>
            )}
            <Grid item lg={8}>
              <Box className={classes.photoContainer}>
                <img src={accommodation.mainPhoto} alt={accommodation.name} className={classes.mainPhoto} />
                <Chip label={t(accommodationTypeToString(accommodation.type))} color="primary" className={classes.typeChip} />
              </Box>
              <Typography variant="h1">{accommodation.name}</Typography>
              <Linkify as="p">{accommodation.description}</Linkify>

              <Typography variant="h3">{t("location_surroundings")}</Typography>
              <Linkify as="p">{accommodation.surroundings}</Linkify>

              {accommodation.amenities.kitchenEquipment ? (
                <React.Fragment>
                  <Typography variant="h3">{t("kitchen_equipment")}</Typography>
                  <Linkify as="p">{accommodation.amenities.kitchenEquipment}</Linkify>
                </React.Fragment>
              ) : null}

              <Typography variant="h3">{t("price")}</Typography>
              <Linkify as="p">{accommodation.price}</Linkify>

              {accommodation.amenities.more && accommodation.amenities.more !== "" ? (
                <React.Fragment>
                  <Typography variant="h3">{t("other")}</Typography>
                  <Linkify as="p">{accommodation.amenities.more}</Linkify>
                </React.Fragment>
              ) : null}
            </Grid>
            <Grid item xs={12} lg={4}>
              <Container className={classes.infoBox}>
                <Typography variant="h2" style={{ color: "#ffffff", marginBottom: "0.3rem" }}>
                  {t("accInfo")}
                </Typography>
                <p className={classes.placeText}>
                  <PlaceIcon fontSize="inherit" /> {regionToString(accommodation.region)}
                </p>

                <Typography variant="h4">{t("amenities")}</Typography>
                <AmenitiesSection showAll accommodation={accommodation} />
              </Container>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h3">{t("findUs")}</Typography>
                </Grid>
                <Grid item xs={12} lg={5} className={classes.directionsBox}>
                  <Typography variant="h4">{t("route")}</Typography>
                  <Linkify as="p">{accommodation.route}</Linkify>
                </Grid>
                <Grid item xs={12} lg={7}>
                  <div className={classes.map}>
                    <MapComponent accommodations={[accommodation]} />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3">{t("contact")}</Typography>
              <Grid container spacing={2}>
                <Grid item>
                  <div className={classes.profileAvatar}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080.05 1080.05">
                      <path fill="#e6e6dd" d="M0 0h1080.05v1080.05H0z"></path>
                      <path
                        d="M907.86 860.52c-84-84-225.68-77.92-248.11-154.82a115.86 115.86 0 01-3.75-48.48c11.28-3 22.06-6.27 32.29-9.85A1119.07 1119.07 0 00720.43 538c.77-.63 1.53-1.3 2.29-2 9.61-9 12.77-19.56 17.34-35.5s6.88-24 6.43-35.55a80 80 0 00-6.81-29.16c2.77-19.95 5.06-40.32 6.74-61.15.35-4.4.63-8.74.92-13.09q1.44-14 1.56-27.47v-.42c.49-65.33-21.86-119.67-60.26-147.33C678 178.68 663 171 642 167.37a130.42 130.42 0 00-30.14-21.06c-21-10.58-39.29-12.56-49.13-13.62-4.1-.44-87.35-8.58-143.66 27.66-45.56 29.31-74.52 88.32-77.63 157.5-3.46 47-.17 85.6 5 115.76a62.17 62.17 0 00-7.13 14.39c-6.1 17.8-2.07 33.1 3.16 52.92 3.88 14.75 6.9 25.62 16.92 35.09a51 51 0 0013.12 8.99 417.16 417.16 0 015.49 50.7c.88 18.19.48 33.2 0 43.11 13.12 4.92 28.08 10 44.59 14.54a111 111 0 01-1.08 51.12C403 775 266 770.07 184.12 851.91c-44.86 44.86-62.46 160.93-64.7 228.09H960.7c1.54-62.47-10.96-177.6-52.84-219.48z"
                        transform="translate(-.1 .05)"
                        fill="#305b7d"
                      ></path>
                      <path
                        fill="#e9d936"
                        d="M483.55 949.18l45.93 31.61a115 115 0 01-31.15 52.83c-30.44 29.22-67.5 32-77.45 32.42-1.2-10.21-3.54-41.61 15.84-73.53a118 118 0 0146.83-43.33zM544.39 976.41l27.24-39.07a99.51 99.51 0 0145.54 26.5c25.19 25.9 27.58 57.42 28 65.89-8.81 1-35.87 3-63.39-13.48a100.75 100.75 0 01-37.39-39.84zM512.48 885.47a253.3 253.3 0 01-66.05-54.82c-23.84-27.9-39.46-65.5-47.2-86.49a20.33 20.33 0 00-20.61-13.24c-14.45 1.08-34.37 6.49-54.32 18.4-8.5 5.08-15.33 9.21-21.05 14.63a20.38 20.38 0 00-1.8 27.55c17.71 22.06 44.34 57.6 77.11 82.56a431.14 431.14 0 00104 58.39zM540.78 883.3a331.47 331.47 0 0075-61.83c26.67-29.55 49.48-63.61 62.09-88a16.53 16.53 0 0115.74-9 123.28 123.28 0 0155.83 17.57 121.81 121.81 0 0130 25.85 16.54 16.54 0 01-2.07 23.31c-27.73 23.25-63.41 50.31-98 72.45a876.29 876.29 0 01-99.66 55z"
                        transform="translate(-.1 .05)"
                      ></path>
                      <path
                        d="M521.22 879.56a146.67 146.67 0 00-52.61 57.13 125.35 125.35 0 0015.84 18.67 129.94 129.94 0 0050.34 30A127.43 127.43 0 00593.62 922a97.72 97.72 0 00-72.4-42.42z"
                        transform="translate(-.1 .05)"
                        fill="#00365f"
                      ></path>
                    </svg>
                  </div>
                </Grid>
                <Grid item>
                  <InformationSection accommodation={accommodation} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3">{t("gallery")}</Typography>
              <ImageList rowHeight={isSmallScreen ? 160 : 250} cols={3}>
                <ImageListItem cols={2}>
                  <Card raised={false} className={classes.mainPhoto}>
                    <CardActionArea disableRipple onClick={() => onImageClick(0)}>
                      <CardMedia component="img" height={isSmallScreen ? 160 : 250} src={accommodation.mainPhoto} />
                    </CardActionArea>
                  </Card>
                </ImageListItem>
                {accommodation.photos.map((photo, index) => {
                  return (
                    <ImageListItem key={index} cols={index === 2 ? 2 : 1}>
                      <Card raised={false} className={classes.mainPhoto}>
                        <CardActionArea disableRipple onClick={() => onImageClick(index + 1)}>
                          <CardMedia component="img" height={isSmallScreen ? 160 : 250} src={photo} />
                        </CardActionArea>
                      </Card>
                    </ImageListItem>
                  );
                })}
              </ImageList>
            </Grid>
          </Grid>
        </Container>
        {toggleGallery !== undefined && galleryOpen !== undefined ? (
          <GalleryModal open={galleryOpen} toggleModal={toggleGallery} images={photoList} startImage={clickedImage} />
        ) : null}
        {props.preview ? null : user.id.length === 0 ? null : (
          <FloatingButton
            onClick={() => {}}
            text={t("edit")}
            color={"brown"}
            toggleButton={user.id.length > 0}
            onOptionClick={floatingButtonOption}
            accommodation={accommodation}
          />
        )}
      </Fragment>
    )
  ) : (
    <span />
  );
};

export default AccommodationScreen;
