import React from 'react';

interface Props{
  color: string,
  className?: string | undefined,
}

const FloorSpace = (props: Props) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 181.000000 212.000000" style={{height: '1.5rem'}} className={props.className}>
      <g fill={props.color} stroke="none" transform="translate(0.000000,212.000000) scale(0.100000,-0.100000)">
        <path d="M960 2068 c-33 -28 -241 -127 -360 -171 -150 -56 -326 -107 -398
        -115 -41 -4 -68 -13 -75 -23 -10 -16 -9 -18 85 -199 48 -91 58 -121 78 -228
        13 -73 29 -131 40 -144 l17 -22 55 36 c30 20 71 44 91 54 37 18 37 18 18 38
        -58 62 -78 184 -42 262 101 222 439 169 459 -71 2 -30 6 -55 8 -55 2 0 39 10
        81 22 43 11 105 24 137 27 39 4 62 12 69 23 11 17 6 29 -92 213 -33 63 -47
        106 -67 205 -33 168 -51 193 -104 148z"/>
        <path d="M600 1664 c-166 -72 -181 -308 -26 -399 114 -67 258 -27 317 87 60
        118 8 262 -113 313 -47 19 -132 19 -178 -1z"/>
        <path d="M986 1365 c-22 -8 -51 -25 -65 -40 -26 -25 -26 -28 -24 -157 1 -103
        5 -134 17 -146 9 -9 18 -13 21 -10 9 9 35 141 35 180 0 50 18 87 51 104 42 21
        75 17 111 -15 32 -29 33 -31 30 -107 -3 -94 -24 -189 -56 -252 l-24 -47 32
        -55 c53 -90 54 -85 -27 -116 -219 -82 -281 -110 -299 -135 -29 -39 -33 -98
        -10 -135 23 -36 502 -393 544 -405 53 -15 118 15 142 65 41 86 19 120 -159
        251 -81 59 -143 111 -138 115 4 4 53 24 108 45 203 75 251 119 249 229 -2 205
        -245 583 -404 630 -64 19 -83 19 -134 1z"/>
        <path d="M1013 1268 c-15 -17 -24 -48 -32 -117 -12 -94 -32 -161 -49 -161 -13
        0 -83 77 -110 121 -38 62 -90 71 -139 26 -37 -34 -30 -74 26 -149 94 -124 173
        -176 252 -165 104 14 170 133 185 335 6 69 4 80 -16 105 -28 36 -86 38 -117 5z"/>
        <path d="M642 995 c-35 -44 -28 -72 28 -126 90 -88 192 -122 268 -90 30 13 48
        36 18 25 -22 -9 -89 13 -138 46 -38 26 -90 82 -141 154 -13 18 -15 17 -35 -9z"/>
      </g>
    </svg>
  )
}

export default FloorSpace;