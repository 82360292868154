import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { Button, CircularProgress, Container, Grid, Stepper, Step, StepLabel, Typography, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Components
import Header from "../components/Header";
import StepOne from "../components/new_accommodation_screen_steps/StepOne";
import StepTwo from "../components/new_accommodation_screen_steps/StepTwo";
import StepThree from "../components/new_accommodation_screen_steps/StepThree";
import StepFour from "../components/new_accommodation_screen_steps/StepFour";
import StepFive from "../components/new_accommodation_screen_steps/StepFive";
import NoUserFallback from "../components/NoUserFallback";

// Store
import { authUser } from "../store/auth_user";
import { newListingState } from "../store/new_listing_state";
import { mapData } from "../store/map_data";
import { loadedAccommodations } from "../store/loaded_accommodations";
import { modalsState } from "../store/modals_state";

// Utils
import { findAccommodation } from "../utils/functions";
import { editAccommodation, getAccommodation, saveAccommodation } from "../utils/database";

// Models
import Accommodation from "../models/Accommodation";
import theme from "../theme/theme";

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    paddingBottom: "3rem",
  },
  container: {
    maxWidth: "580px",
  },
  mainHeading: {
    marginBottom: "2rem",
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  root: {
    maxWidth: 650,
    margin: "auto",
    flexWrap: "nowrap",
  },
  title: {
    marginBottom: 50,
    textAlign: "center",
  },
  stepper: {
    marginBottom: 40,
    padding: 0,
  },
}));

const NewOrEditAccommodationScreen = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(false);
  const [listingState, setListingState] = useRecoilState(newListingState);
  const resetListingState = useResetRecoilState(newListingState);
  const navigate = useNavigate();
  const [modals, setModals] = useRecoilState(modalsState);
  const [t] = useTranslation("common");
  const steps = [t("description"), t("amenities"), t("additional_amenities_and_contact_info"), t("images"), t("preview")];
  const user = useRecoilValue(authUser);
  const [loading, setLoading] = useState(false);
  const [unAuthorized, setUnAuthorized] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const mapAccommodations = useRecoilValue(mapData);
  const homeAccommodations = useRecoilValue(loadedAccommodations);
  const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);
  const params = useParams();

  useEffect(() => {
    if (params.id && (user.listings.includes(params.id) || user.isAdmin)) {
      const acc = findAccommodation(params.id, [...mapAccommodations.readyAccommodations, ...homeAccommodations.accommodations]);
      if (!acc) {
        setInitLoading(true);
        getAccommodation(params.id).then((result) => {
          const accommodation = Accommodation.fromDbObject(result);

          setListingState(accommodation.getAccommodationAsObj());
          setInitLoading(false);
        });
      } else {
        setListingState(acc.getAccommodationAsObj());
      }
    } else if (params.id && user.id !== "" && (!user.isAdmin || !user.listings.includes(params.id))) {
      setUnAuthorized(true);
    } else if (!params.id && user.id !== "") {
      setListingState(Accommodation.getEmptyAccommodationAsObj());
    }

    // eslint-disable-next-line
  }, [user]);

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <StepOne allTouched={error} />;
      case 1:
        return <StepTwo allTouched={error} />;
      case 2:
        return <StepThree allTouched={error} />;
      case 3:
        return <StepFour error={error} onDeleteImage={(image) => setImagesToDelete([...imagesToDelete, image])} />;
      case 4:
        return <StepFive />;
      default:
        return <></>;
    }
  };

  const handleNext = () => {
    if (activeStep === 4) {
      setLoading(true);
      if (params.id) {
        editAccommodation(
          Accommodation.fromNewListingObject(listingState, user, listingState.id),
          [...listingState.mainImageFile],
          [...listingState.otherImagesFiles],
          imagesToDelete
        ).then((edited) => {
          if (edited) {
            if (!user.isAdmin) {
              setModals({
                ...modals,
                newOrEditListingModal: "edit",
              });
            }

            resetListingState();
            setLoading(false);

            navigate("/");
          } else {
            setLoading(false);
          }
        });
      } else {
        saveAccommodation(Accommodation.fromNewListingObject(listingState, user, listingState.id), user.id, [
          ...listingState.mainImageFile,
          ...listingState.otherImagesFiles,
        ]).then((saved) => {
          if (saved) {
            if (!user.isAdmin) {
              setModals({
                ...modals,
                newOrEditListingModal: "new",
              });
            }

            resetListingState();
            setLoading(false);

            navigate("/");
          } else {
            setLoading(false);
          }
        });
      }
    } else {
      if (listingState.activeStepValid) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setError(false);
        setListingState({
          ...listingState,
          location: { ...listingState.location },
          activeStepValid: false,
        });
      } else {
        setError(true);
      }
    }
  };

  const handleBack = () => {
    setListingState({
      ...listingState,
      location: { ...listingState.location },
      activeStepValid: true,
    });
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onProfileClick = () => {
    navigate("/profile");
  };

  const goBack = () => {
    navigate("/");
  };

  if (unAuthorized) {
    goBack();
  }

  if (user.id === "") {
    return (
      <div style={{ width: "100%", margin: "auto" }}>
        <Header onProfileClick={onProfileClick} onNewAccommodationClick={() => {}} />
        <NoUserFallback />
      </div>
    );
  }

  if (initLoading) {
    return (
      <Grid container alignItems="center" justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <React.Fragment>
      <Header onProfileClick={onProfileClick} onNewAccommodationClick={() => {}} />

      {loading ? (
        <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 30 }}>
          <CircularProgress />
        </Grid>
      ) : (
        <Container className={classes.mainContainer} disableGutters>
          <Container disableGutters className={classes.container}>
            <Typography variant="h1" className={classes.mainHeading}>
              {t("create_new_listing")}
            </Typography>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{
                "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
                  borderColor: theme.palette.primary.main,
                },
                "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
                  borderColor: theme.palette.primary.main,
                },
              }}
            >
              {steps.map((label, index) => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Container>
          <Container className={activeStep !== 4 ? classes.container : undefined} disableGutters>
            {activeStep === steps.length ? (
              <Typography>{t("all_steps_completed")}</Typography>
            ) : (
              <div>
                {getStepContent(activeStep)}
                <Grid container spacing={1}>
                  <Grid item>
                    <Button variant="outlined" color="primary" disabled={activeStep === 0} onClick={handleBack} disableElevation>
                      {t("back")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary" onClick={handleNext} disableElevation>
                      {activeStep === steps.length - 1 ? t("finish") : t("next")}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            )}
          </Container>
        </Container>
      )}
    </React.Fragment>
  );
};

export default NewOrEditAccommodationScreen;
