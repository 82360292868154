import {
  ButtonBase,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import Accommodation from "../models/Accommodation";
import { adminState } from "../store/admin_state";
import { getAllAccommodationsQuery } from "../utils/database";
import { filterAdminAccomodations, typedKeys } from "../utils/functions";

interface Props {
  onClickListing: (id: string, name: string) => void;
}

const useStyles = makeStyles({
  mainGrid: {
    marginTop: 50,
  },
  checkBoxContainer: {
    marginTop: 20,
    marginBottom: 10,
  },
  divider: {
    width: "100%",
    marginBottom: 50,
  },
  title: {
    textAlign: "center",
    fontSize: 28,
    fontWeight: "bold",
    marginBottom: 20,
  },
});

const AdminSection = (props: Props) => {
  const classes = useStyles();
  const [t] = useTranslation("common");
  const [allListings, setAllListings] = useRecoilState(adminState);
  const [loading, setLoading] = useState(false);
  const [tablePage, setTablePage] = useState(0);
  const [checkboxes, setCheckboxes] = useState({
    unapproved: false,
    new: false,
    edited: false,
    deactivated: false,
  });

  useEffect(() => {
    setLoading(true);
    getMoreAccommodations(true).then(() => {
      setLoading(false);
    });

    // eslint-disable-next-line
  }, []);

  const getMoreAccommodations = async (
    withoutTs: boolean,
    newCheckboxes?: {
      unapproved: boolean;
      new: boolean;
      edited: boolean;
      deactivated: boolean;
    }
  ) => {
    const result = await getAllAccommodationsQuery(
      filterAdminAccomodations(newCheckboxes ?? checkboxes, withoutTs ? 0 : allListings.lastTs),
      10
    );
    const tempAcc = withoutTs ? [] : [...allListings.accommodations];
    let ts = 0;
    let more = false;

    if (result.hasMoreResults()) {
      const query = await result.fetchNext();
      if (query.resources) {
        query.resources.forEach((obj) => {
          tempAcc.push(Accommodation.fromDbObject(obj));
        });

        ts = query.resources[query.resources.length - 1]._ts;
        more = query.hasMoreResults;
      }
    }

    setAllListings({
      accommodations: [...tempAcc],
      lastTs: ts,
      hasMore: more,
    });
  };

  const getListingState = (listing: Accommodation): string => {
    if (listing.edited) return "Edited";
    if (!listing.approved && !listing.edited) return "New";
    if (listing.deactivated) return "Deactivated";

    return "Live";
  };

  const getRowColor = (listing: Accommodation): string => {
    if (listing.edited) return "yellow";
    if (!listing.approved && !listing.edited) return "green";
    if (listing.deactivated) return "red";

    return "white";
  };

  const onChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    if ((page + 1) * 10 > allListings.accommodations.length) {
      setLoading(true);
      getMoreAccommodations(false).then(() => {
        setLoading(false);
        setTablePage(page);
      });
    } else {
      setTablePage(page);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    let newCheckboxesState = {
      unapproved: false,
      new: false,
      edited: false,
      deactivated: false,
    };

    setLoading(true);
    getMoreAccommodations(true, { ...newCheckboxesState, [event.target.name]: checked }).then(() => {
      setLoading(false);
    });

    setCheckboxes({ ...newCheckboxesState, [event.target.name]: checked });
  };

  return (
    <Grid direction="column" className={classes.mainGrid} container justifyContent="center" alignItems="center">
      <Divider variant="fullWidth" className={classes.divider} />
      <Typography variant="h2">{t("all_listings")}</Typography>
      {loading ? (
        <CircularProgress style={{ marginTop: 30 }} />
      ) : (
        <Fragment>
          <Grid container alignItems="center" direction="row">
            <Typography variant="h4" style={{ marginBottom: 0, fontWeight: 700, marginRight: 10 }}>
              {t("show_only")}:
            </Typography>
            <FormGroup row>
              {typedKeys(checkboxes).map((checkbox) => (
                <FormControlLabel
                  key={checkbox}
                  control={
                    <Checkbox
                      id={checkbox}
                      checked={checkboxes[checkbox]}
                      onChange={handleCheckboxChange}
                      name={checkbox}
                      color="primary"
                      disabled={loading}
                    />
                  }
                  label={t(checkbox)}
                />
              ))}
            </FormGroup>
          </Grid>
          <TableContainer component={Paper}>
            <Table aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <TableCell>id</TableCell>
                  <TableCell>{t("name")}</TableCell>
                  <TableCell>{t("state")}</TableCell>
                  <TableCell>{t("approved")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allListings.accommodations
                  .filter((value, index) => index < (tablePage + 1) * 10 && index >= tablePage * 10)
                  .map((row) => (
                    <TableRow style={{ backgroundColor: getRowColor(row) }} key={row.name}>
                      <TableCell>
                        <ButtonBase onClick={() => props.onClickListing(row.id, row.name)}>{row.id}</ButtonBase>
                      </TableCell>
                      <TableCell>
                        <ButtonBase onClick={() => props.onClickListing(row.id, row.name)}>{row.name}</ButtonBase>
                      </TableCell>
                      <TableCell>
                        <ButtonBase onClick={() => props.onClickListing(row.id, row.name)}>{getListingState(row)}</ButtonBase>
                      </TableCell>
                      <TableCell>
                        <ButtonBase onClick={() => props.onClickListing(row.id, row.name)}>{row.approved ? t("yes") : t("no")}</ButtonBase>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={4}
                    count={-1}
                    rowsPerPage={10}
                    page={tablePage}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onPageChange={onChangePage}
                    rowsPerPageOptions={[10]}
                    nextIconButtonProps={{
                      disabled: !allListings.hasMore,
                    }}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Fragment>
      )}
    </Grid>
  );
};

export default AdminSection;
