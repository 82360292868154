import React, { useState } from "react";
import { AppBar, Toolbar, ButtonBase, Avatar, Menu, MenuItem, Button, Link, ListItemText, ListItemIcon, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { authUser } from "../store/auth_user";
import { useTranslation } from "react-i18next";
import { modalsState } from "../store/modals_state";
import { profileState } from "../store/profile_state";
import Cookies from "js-cookie";
import PersonIcon from "@mui/icons-material/Person";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ScouternaTextLogo from "../assets/customIcons/scouternaTextLogo";

const useStyles = makeStyles({
  appBarLink: {
    marginRight: 20,
  },
  spacer: {
    height: "64px",
    display: "block",
    width: "100%",
  },
});

interface Props {
  onNewAccommodationClick: () => void;
  onProfileClick: () => void;
}

const Header = (props: Props) => {
  const user = useRecoilValue(authUser);
  const resetUser = useResetRecoilState(authUser);
  const resetProfile = useResetRecoilState(profileState);
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [modals, setModals] = useRecoilState(modalsState);
  const [t] = useTranslation("common");

  const onAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(!menuOpen);
  };

  const onLoginClick = async () => {
    setModals({
      ...modals,
      loginModal: true,
    });
  };

  const onLogout = () => {
    resetUser();
    resetProfile();
    Cookies.remove("SimpleSAMLSessionID");
    Cookies.remove("SimpleSAMLAuthToken");
  };

  return (
    <React.Fragment>
      <span className={classes.spacer} />
      <AppBar position="fixed" elevation={0} style={{ backgroundColor: "#FFFFFF", height: "64px" }}>
        <Container maxWidth="xl" disableGutters>
          <Toolbar>
            <Link href="/">
              <ScouternaTextLogo color="#003660" />
            </Link>
            <span style={{ flexGrow: 1 }} />
            {user.id.length === 0 ? (
              <Button variant="contained" color="primary" style={{ height: "2.6rem" }} disableElevation onClick={onLoginClick}>
                {t("login")}
              </Button>
            ) : (
              <ButtonBase disableRipple onClick={onAvatarClick}>
                <Avatar style={{ backgroundColor: "white" }}>
                  <AccountCircleRoundedIcon fontSize="large" style={{ color: "#043a63" }} />
                </Avatar>
                <Menu open={menuOpen} anchorEl={anchorEl} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                  <MenuItem onClick={props.onProfileClick} style={{ padding: "0 1rem" }}>
                    <ListItemIcon>
                      <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t("my_profile")} />
                  </MenuItem>
                  <MenuItem onClick={props.onNewAccommodationClick} style={{ padding: "0 1rem" }}>
                    <ListItemIcon>
                      <AddCircleOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t("create_new_listing")} />
                  </MenuItem>
                  <MenuItem onClick={onLogout} style={{ padding: "0 1rem" }}>
                    <ListItemIcon>
                      <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t("log_out")} />
                  </MenuItem>
                </Menu>
              </ButtonBase>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;
